<script>

    import {createEventDispatcher, onMount} from "svelte";
    import {activePortal} from "../../store";
    const dispatch = createEventDispatcher();

    window.addEventListener("message", event => {
        if (event.data.type === "redirectOnComplete") {
            if (event.data.state.lead.created) {
                console.log(event);
                //dispatch('quote', '123');
            }
        }
    });

    export let meta;
    export let url;
    export let form;

    onMount( async () => {

        const loader = new Promise((resolve, reject) => {
            if (window.ClickAppForm) {
                resolve(window.ClickAppForm);
                return;
            }
            const script = document.createElement("script");
            script.src = url;
            script.crossOrigin = "anonymous";
            script.onload = () => {
                resolve(window.ClickAppForm);
            };
            script.onerror = reject;
            document.body.appendChild(script);
        });

        const tracking = await window.ClickAppTrack;
        tracking.applicationURL = location.href;

        await loader.then((caf) => {
            caf.default(document.getElementById('appForm'), {
                ...form,
                phoneNumber: $activePortal.brand.telephone,
                documentURLs: $activePortal.documentURLs,
                tracking: tracking,
                initialValues: {...meta.query, "residentialStatus": "Homeowner"},
                minLoanAmount: $activePortal.form.loan.min,
                maxLoanAmount: $activePortal.form.loan.max,
                target: $activePortal.form.target,
                believe: true,
                redirectOnComplete: false
            });
            setTimeout(() => {
                dispatch("loaded", true)
            }, 250);
        });
    });
</script>

<div id="appForm"></div>
