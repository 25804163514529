<script>
  import {activeCase} from "../../Shared/store";
  import TrustBox from "../../Shared/Components/TrustBox.svelte";
  $: isNotProtect = $activeCase.portal.api !== 'protect';
</script>

<div class="footer">
    <div class="container">
        <TrustBox
                templateId={ $activeCase.portal.trustPilot.templateId }
                businessUnitId={ $activeCase.portal.trustPilot.businessUnitId }
                domain={ $activeCase.portal.brand.domain }
        />
    </div>
    <footer>
        {#if isNotProtect}
            <p>
                This { $activeCase.portal.term.toLowerCase() } in Principle does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. This is only an indicative quote and advice specific to your needs & circumstances will be given following a full application and prior to receiving the lenders mortgage offer.
            </p>
            <p>
                <strong
                >Your home may be repossessed if you do not keep up repayments on your mortgage.</strong
                >
            </p>
        {/if}
    </footer>
</div>

<style>

    footer, .footer {
        padding: 0 1rem 1rem 1em;
        border-top: 1px solid #cccccc;
    }

</style>