<NoSidebar titleClass="third-party-consent-header">
    <div class="third-party-consent">
        <div class="row">
            <div class="col">
                <div class="partnership">
                    {#if partnership}
                        {#if partnership.showLogo === false}
                            <img
                                    src="/{ $activePortal.brand.logo }"
                                    alt="{ $activePortal.brand.name }"
                                    width="200px"
                            />
                        {:else}
                            <h5>In partnership with</h5>
                            <img
                                    src={`/partners/logos/${partnership.logo}`}
                                    alt={partnership.name}
                            />
                        {/if}
                    {/if}
                    <h1>Welcome!</h1>
                </div>
                {#if $activePortal.api === 'first-charge'}
                    <h3>You're nearly there! Click below to find <br class="hidden-mobile"/>your best deal Believe Finance!</h3>
                    <h4>Why choose Believe Finance</h4>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> We ﬁnd you the best mortgage deal from our panel of top lenders</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Our bespoke technology makes everything easy</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Our friendly, expert team supports you all the way</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Access to exclusive offers</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Great mortgage deals without the hassle</div>
                {/if}
                {#if $activePortal.api === 'specialist-finance'}
                    <h3>You're nearly there! Click below to find <br class="hidden-mobile"/>your best deal with Believe Specialist Finance!</h3>
                    <h4>Why choose Believe Specialist Finance</h4>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Loans from £10,000 to £20,000,000</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Our expert team arranges your loan and supports you all the way</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Secure your loan against residential or business property</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point"/> Fast access to the finance you're looking for</div>
                {/if}
                {#if $activePortal.api === 'second-charge'}
                    <h3>You're nearly there! Click below to find <br class="hidden-mobile"/>your best deal with Believe Loans!</h3>
                    <h4>Why choose Believe Loans</h4>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> Simple, easy application</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> We search lenders to get you the best deal</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> 5-star customer service</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> No impact on your credit score</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> No upfront fees or hidden charges</div>
                {/if}
                {#if $activePortal.api === 'protect'}
                    <h3>You're nearly there! Click below to find <br class="hidden-mobile"/>the right cover for you with Believe Protect!</h3>
                    <h4>Why choose Believe Protect</h4>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> Free review from specialist insurance advisers</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> Access to the UK's leading insurance providers</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> Cover available for 180 different serious illnesses</div>
                    <div class="bullet"><img src="/partners/tick.png" alt="Bullet point" /> Non-medical plans available if you have pre-existing medical conditions</div>
                {/if}

                <div class="third-party-consent-check">
                    <label for="thirdPartyConsent">
                        <input id="thirdPartyConsent" type="checkbox" bind:checked={thirdPartyConsent} style="transform: scale(1.5);margin-right:0.5rem;margin-bottom:0.5rem;"/>
                        I agree to the <a href={termsAndConditions} target="_blank"><strong>terms &amp; conditions</strong></a> and <a href={privacyPolicy} target="_blank"><strong>privacy policy</strong></a>.
                    </label>
                </div>
                <div class="third-party-consent-button">
                    <button class="button pill fill red" disabled={working || !thirdPartyConsent} on:click={confirmConsent}>{ working ? "Please Wait..." : "Find my best deal" }</button>
                </div>
            </div>
            <div class="col">
                <img src="/partners/app.png" alt="Believe App" class="img-fluid app-image" />
            </div>
        </div>
    </div>
</NoSidebar>

<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
    import {onMount, setContext} from "svelte";

    //
    // Set branding via portal
    //
    import {activePortal} from "../../Shared/store";
    import {portals} from "../../Shared/portals";
    import {thirdParties} from "./third-parties";
    import api from "../../Shared/api";
    import {router} from "tinro";
    export let code;
    export let partner;
    export let portal;
    setContext('code', code);
    setContext('partner', partner);
    setContext('portal', portal);
    activePortal.set(portals.all.find(x => x.api === portal));

    //
    // Links
    //
    $:termsAndConditions = "https://www." + $activePortal.brand.domain + "/terms";
    $:privacyPolicy = "https://www." + $activePortal.brand.domain + "/privacy";
    $:defaultRedirect = $activePortal.slug;

    //
    // Local
    //
    let thirdPartyConsent = false;
    let working = false;
    let query = {};
    $: partnership = thirdParties.find(x => x.partner === partner);

    //
    // Submission
    //
    async function confirmConsent() {
        if (thirdPartyConsent === false) return;
        working = true;
        const result = await api.autoAipThirdPartyConsent(portal, code);
        if (result.redirect) {
            window.location.assign(result.redirect);
            return;
        }
        router.goto(`/quote/${defaultRedirect}/${code}`);
    }

</script>

<style type="text/css">

    :global(.sidebar) {
        background: linear-gradient(90deg, #202548 50%, #70B6CF 100%) !important;
    }
    :global(.sidebar__wrapper) {
        background-size: auto 100% !important;
        background: url('/partners/bg.png') no-repeat right top !important;

    }
    :global(.sidebar__main__card) {
        margin:5rem !important;
        border-radius: 2rem !important;
    }
    .third-party-consent {
        text-align: left !important;
    }
    .third-party-consent .partnership {
        margin-bottom:1.5rem;
    }
    .third-party-consent h3 {
        margin-bottom:2.5rem;
    }
    .third-party-consent p,
    .third-party-consent ul li {
        line-height: 1.5rem;
    }
    .third-party-consent .bullet {
        display: flex;
        align-items: center;
        margin-bottom:1rem;
    }
    .third-party-consent .bullet img {
        margin-right:0.75rem;
        width:1.25rem;
    }
    .third-party-consent .img-fluid {
        max-width: 100%;
    }
    .third-party-consent .row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom:1rem;
    }
    .third-party-consent .col {
        flex: 1;
    }
    .third-party-consent-check {
        margin: 2rem 0;
        padding: 0 0.2rem;
    }
    .third-party-consent-check a {
        text-decoration: none;
    }
    @media screen and (max-width: 700px) {
        .third-party-consent img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 1000px) {
        .third-party-consent .row {
            flex-direction: column;
        }
        :global(.sidebar__main__card) {
            margin:2rem !important;
            border-radius: 1.5rem !important;
        }
        .hidden-mobile {
            display: none;
            visibility: hidden;
        }
        .third-party-consent-button {
            text-align:center;
        }
    }
</style>