<script>

    /* Passed Vars */
    export let fieldName;
    export let dataType;
    export let required;
    export let options;
    export let conditions = [];
    export let initialOption = true;
    export let value;

    export let borderless = false;
    export let hideAddon = false;

</script>

<div class="field__wrapper" class:borderless={borderless}>
    {#if dataType === "number"}
        {#if hideAddon === false}
        <div class="field__addon">&pound;</div>
        {/if}
        <input
                required={required}
                type="number"
                bind:value={value} />
    {/if}
    {#if dataType === "dropdown"}
        <select
                required={required}
                bind:value={value}>
            {#if initialOption}
            <option label="Select an option" value="" />
            {/if}
            {#each options as option}
                <option value={option.value}>{option.label}</option>
            {/each}
        </select>
    {/if}
    {#if dataType === "conditional"}
        <select
                required={required}
                bind:value={value}>
            {#if initialOption}
                <option label="Select an option" value="" />
            {/if}
            {#each conditions as option}
                <option value={option.value}>{option.label}</option>
            {/each}
        </select>
    {/if}
</div>

<style>

    .field__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
    }

    .field__wrapper select,
    .field__wrapper input,
    .field__wrapper .field__addon{
        padding: 0.5rem;
        min-width: 100%;
        flex-grow: 1;
        height: 36px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 0.3rem;

    }

    .field__wrapper .field__addon {
        min-width: 36px;
        max-height: 34px !important;
        margin:0;
        padding:0;
        line-height: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right:none;
        background-color: rgba(0, 0, 0, 0.025);
    }

    .field__wrapper .field__addon + select,
    .field__wrapper .field__addon + input {
        min-width: calc(100% - 36px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
    }

    .field__wrapper.borderless select,
    .field__wrapper.borderless input,
    .field__wrapper.borderless .field__addon {
        background-color:white;
        padding: 1rem 0.75rem;
        height: auto;
        border:none;
        box-shadow: 1px 1px 1px rgba(0,0,0,0.1);
    }

    .field__wrapper.borderless .field__addon {
        height: 50px !important;
        padding: 0.5rem 0;
    }

</style>