import {get, writable} from "svelte/store";
import jwtDecode from "jwt-decode";
import api from "./api";
import {compareCaseReference} from "./utils";

// function for comparing cases in order to pick the best one to show first
const compareCasesStatusValues = {
  PRO: 1,
  RES: 1,
  DOX: 2,
  LEN: 3,
  OFF: 4,
  PDO: 5
};

function compareCases(a, b) {
  // first sort by status
  const statusA = compareCasesStatusValues[a.status] || 6;
  const statusB = compareCasesStatusValues[b.status] || 6;
  if (statusA < statusB) {
    return -1;
  }
  if (statusA > statusB) {
    return 1;
  }

  // otherwise sort by reference
  return compareCaseReference(a.reference, b.reference);
}

//
// Portals
//

export const defaultPortal =  {
  standard: {
    brand: {
      name: "Believe",
      shortName: "Believe",
      logo: "believe.png"
    }
  },
  all: []
}

const portalPromise = api.portals();

export const _portals = writable(defaultPortal);
export const portals = async () => {
  if (get(_portals).all.length === 0) {
    await setPortals();
  }
  return get(_portals);
}

async function setPortals() {
  console.log("setPortals", portalPromise);
  let p = await portalPromise;
  console.log("setPortals - Api", p);
  let s = {
    ...defaultPortal,
    all: p
  }
  _portals.set(s);
}

//
// Sign In / Sign Out
//
const storedUser = JSON.parse(sessionStorage.getItem("user"));
const sessionUser = storedUser ? {...storedUser, signedIn: true} : {signedIn: false};
export const user = writable(sessionUser);
user.subscribe(async (user) => {
  if (user.signedIn) {

    // Set Vars
    api.setToken(user.token);
    api.setAccountId(user.accountId);
    api.setCustomerName(user.profile.name);

    // Portals
    let p = await portals();

    if (!user.requiresVerification) {
      // Fetch all portal accounts
      const productCases = await Promise.all(
          p.all.map(x => api.authedRequest(`/api/${x.api}/${user.accountId}`, {}, () => { return [] }))
      );

      // Map and match portals
      const cases = productCases.map((pc, i) => (pc || []).map(c => ({
        ...c,
        portal: p.all[i]
      }))).flat();

      // Set the case list (all cases for each portal)
      caseList.set(cases);

      // check stored case and load that one by default, or the most recent
      const storedCase = JSON.parse(sessionStorage.getItem("case")) || {};
      const defaultCase = cases.find(x => x.reference === storedCase.reference && x.portal.api === storedCase.product) || cases.sort(compareCases)[0];
      activeCase.set(defaultCase);
    }
  } else {
    api.setToken(null);
    api.setAccountId(null);
    api.setCustomerName(null);
    api.setCaseId(null)
  }
});

export function signIn(u) {
  document.cookie = `access_token=${u.token}; path=/; secure; samesite=strict`;
  const accountId = jwtDecode(u.token).sub.split("@")[0];
  u = {...u, accountId};
  sessionStorage.setItem("user", JSON.stringify(u));
  user.set({...u, signedIn: true});
}

export function completeVerification(token) {
  user.update(u => {
    let updatedUser = {
      token,
      profile: u.profile,
      expires: Date.now() + 3600000,
      requiresVerification: false
    };
    document.cookie = `access_token=${updatedUser.token}; path=/; secure; samesite=strict`;
    const accountId = jwtDecode(updatedUser.token).sub.split("@")[0];
    updatedUser = {...updatedUser, accountId};
    sessionStorage.setItem("user", JSON.stringify(updatedUser));
    return {...updatedUser, signedIn: true};
  });
}

export function signInPreview(u) {
  document.cookie = `access_token=${u.token}; path=/; secure; samesite=strict`;
  sessionStorage.setItem("user", JSON.stringify(u));
  user.set({...u, signedIn: true});
}

export function signOut() {
  sessionStorage.removeItem("user");
  user.set({signedIn: false});
  resetCase();
}

//
// Case
//

export const caseList = writable(null);
export const activeCaseData = writable(null);
export const activePortal = writable(defaultPortal.standard);
export const activeCase = writable(null);
activeCase.subscribe(async (c) => {
  api.setCaseId(c ? c.id : null);
  api.setCaseReference(c ? c.reference : null);
  activePortal.set(c ? c.portal : defaultPortal.standard);
  if (c) {
    await prepareCase(c);
  } else {
    activeCaseData.set(null);
  }
});

export function resetCase() {
  activeCase.set(null);
  caseList.set(null);
  activeCaseData.set(null);
  activePortal.set(defaultPortal.standard);
}

export async function prepareCase(selectedCase) {
  // Store active case reference
  sessionStorage.setItem("case", JSON.stringify({ reference: selectedCase.reference, product: selectedCase.portal.api }));
  await refreshCase();
}

export async function refreshCase() {
  // Fetch all data
  let [needs, inbound, outbound, protection] = await Promise.all([
    api.needs(),
    api.documents(false),
    api.documents(true),
    api.protection()
  ]);
  activeCaseData.set({
    todo: generateTodoList(activeCase, needs),
    needs: needs,
    documents: {
      inbound: inbound,
      outbound: outbound
    },
    protection: protection
  });
}

export function generateTodoList(selectedCase, needs) {
  // create todo list from needs
  let todo = needs || [];

  // add smart search / e-sign
  const smartSearchComplete = sessionStorage.getItem("smartSearchComplete") === "yes";
  const eSignsComplete = JSON.parse(sessionStorage.getItem("eSignComplete")) || [];

  for (const a of todo) {
    for (const t of a.needs) {
      if (t.type === "smartsearch" && t.authorised && smartSearchComplete) {
        t.completed = true;
      } else if (t.type === "esign" && t.authorised && eSignsComplete.includes(t.id)) {
        t.completed = true;
      }
    }
  }

  // Ensure items
  todo = todo.filter((obj) => { return ![null, undefined].includes(obj) })

  // sort list
  todo.sort((a, b) => {
    if (a.completed === b.completed) {
      if (a.type === b.type) {
        return (a.description || "").localeCompare(b.description || "");
      }
      return a.type - a.type;
    }
    return a.completed - b.completed
  });

  return todo;
}
