<script>

    import {user} from "../../Shared/store";

    import Sidebar from "../Layouts/Sidebar.svelte";
    import Nav from "./Elements/Nav.svelte";

    export let hideNavigation = false;
    export let title = "";

    let classes = '';
    export { classes as class };

</script>

<Sidebar title={title} class={`sidebar ${classes || ''}`}>
    <div slot="sidebar">
        {#if ($user.signedIn && !hideNavigation)}
            <Nav/>
        {/if}
    </div>
    <div slot="footer">
        <slot name="footer"></slot>
    </div>
    <slot></slot>
</Sidebar>

<style>
</style>