<script>
  import {onMount} from "svelte";
  import {router} from "tinro";
  import {signInPreview} from "../Shared/store";

  export let accountId;
  export let reference = null;
  export let product = null;

  onMount(() => {
    const token = location.hash.slice(1);

    const user = {
      token: token,
      profile: {name: "Preview"},
      accountId
    };

    // store case ref if provided to show a particular case, otherwise show default
    if (reference && product) {
      sessionStorage.setItem("case", JSON.stringify({reference: Number(reference), product}));
    } else {
      sessionStorage.removeItem("case");
    }

    signInPreview(user);
    router.goto("/");
  });
</script>
