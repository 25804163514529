export const productValues = [
  {rate: {type: "variable"}, value: "variable", description: "Variable"},
  {rate: {type: "tracker"}, value: "tracker", description: "Tracker"},
  {rate: {type: "fixed", minTerm: 12, maxTerm: 23}, value: "fixed-12", description: "Fixed 1 Year"},
  {rate: {type: "fixed", minTerm: 24, maxTerm: 35}, value: "fixed-24", description: "Fixed 2 Years"},
  {rate: {type: "fixed", minTerm: 36, maxTerm: 47}, value: "fixed-36", description: "Fixed 3 Years"},
  {rate: {type: "fixed", minTerm: 48, maxTerm: 59}, value: "fixed-48", description: "Fixed 4 Years"},
  {rate: {type: "fixed", minTerm: 60, maxTerm: 71}, value: "fixed-60", description: "Fixed 5 Years"}
]

export const employmentStatus = [
  {value: "employed", label: "Employed"},
  {value: "selfEmployed", label: "Self-Employed"},
  {value: "retired", label: "Retired"},
  {value: "homemaker", label: "Homemaker"},
  {value: "student", label: "Student"},
  {value: "unemployed", label: "Unemployed"}
];

export const lenders = [
  {value: null, label: "Not Applicable"},
  {value: "Accord Mortgages", label: "Accord Mortgages"},
  {value: "Adam & Company plc", label: "Adam & Company plc"},
  {value: "Advantage", label: "Advantage"},
  {value: "Affirmative Finance Ltd", label: "Affirmative Finance Ltd"},
  {value: "Ahli United Bank (UK) plc", label: "Ahli United Bank (UK) plc"},
  {value: "AIB Group (UK) plc", label: "AIB Group (UK) plc"},
  {value: "Airdrie Savings Bank", label: "Airdrie Savings Bank"},
  {value: "Aldermore Bank plc", label: "Aldermore Bank plc"},
  {value: "Alliance & Leicester plc", label: "Alliance & Leicester plc"},
  {value: "Allied Irish Bank (GB)", label: "Allied Irish Bank (GB)"},
  {value: "Amber Homeloans Ltd", label: "Amber Homeloans Ltd"},
  {value: "Arbuthnot Latham", label: "Arbuthnot Latham"},
  {value: "Atom Bank", label: "Atom Bank"},
  {value: "Aviva Equity Release UK Ltd", label: "Aviva Equity Release UK Ltd"},
  {value: "Bank of China", label: "Bank of China"},
  {value: "Bank of Cyprus UK", label: "Bank of Cyprus UK"},
  {value: "Bank of Ireland", label: "Bank of Ireland"},
  {value: "Bank of Scotland plc", label: "Bank of Scotland plc"},
  {value: "Barclays Bank plc", label: "Barclays Bank plc"},
  {value: "Barnsley Building Society", label: "Barnsley Building Society"},
  {value: "Basinghall Finance plc", label: "Basinghall Finance plc"},
  {value: "Bath Building Society", label: "Bath Building Society"},
  {value: "Beverley Mortgages", label: "Beverley Mortgages"},
  {value: "Birmingham Midshires", label: "Birmingham Midshires"},
  {value: "Bluestone Mortgages", label: "Bluestone Mortgages"},
  {value: "BM Solutions Mortgages", label: "BM Solutions Mortgages"},
  {value: "Bradford & Bingley plc", label: "Bradford & Bingley plc"},
  {value: "Bristol & West plc", label: "Bristol & West plc"},
  {value: "Britannia Building Society", label: "Britannia Building Society"},
  {value: "Britannic Money plc", label: "Britannic Money plc"},
  {value: "Buckinghamshire Building Society", label: "Buckinghamshire Building Society"},
  {value: "Cambridge Building Society", label: "Cambridge Building Society"},
  {value: "Capital Home Loans", label: "Capital Home Loans"},
  {value: "Castle Trust", label: "Castle Trust"},
  {value: "Central Homeloans Ltd", label: "Central Homeloans Ltd"},
  {value: "Charter Court Financial Services Ltd", label: "Charter Court Financial Services Ltd"},
  {value: "Chelsea Building Society", label: "Chelsea Building Society"},
  {value: "Cheltenham & Gloucester plc", label: "Cheltenham & Gloucester plc"},
  {value: "Cheshire Building Society", label: "Cheshire Building Society"},
  {value: "CHL Mortgages", label: "CHL Mortgages"},
  {value: "Chorley & District Building Society", label: "Chorley & District Building Society"},
  {value: "Church House Trust", label: "Church House Trust"},
  {value: "CIS Mortgage Maker Ltd", label: "CIS Mortgage Maker Ltd"},
  {value: "Clydesdale Bank plc", label: "Clydesdale Bank plc"},
  {value: "Commercial First", label: "Commercial First"},
  {value: "Counties Home Loan Management", label: "Counties Home Loan Management"},
  {value: "Coutts & Co", label: "Coutts & Co"},
  {value: "Coventry Building Society", label: "Coventry Building Society"},
  {value: "Crocus Home Loans Ltd", label: "Crocus Home Loans Ltd"},
  {value: "Cumberland Mortgages", label: "Cumberland Mortgages"},
  {value: "Danske Bank", label: "Danske Bank"},
  {value: "Darlington Building Society", label: "Darlington Building Society"},
  {value: "DB UK Bank Ltd", label: "DB UK Bank Ltd"},
  {value: "Derbyshire Building Society", label: "Derbyshire Building Society"},
  {value: "Derbyshire Home Loans Ltd", label: "Derbyshire Home Loans Ltd"},
  {value: "Direct Line Financial Services Ltd", label: "Direct Line Financial Services Ltd"},
  {value: "Dudley Building Society", label: "Dudley Building Society"},
  {value: "Dunfermline Building Society", label: "Dunfermline Building Society"},
  {value: "Earl Shilton Mortgages", label: "Earl Shilton Mortgages"},
  {value: "Ecology Building Society", label: "Ecology Building Society"},
  {value: "Edeus", label: "Edeus"},
  {value: "Egg Banking plc", label: "Egg Banking plc"},
  {value: "E-Mex Home Funding Ltd", label: "E-Mex Home Funding Ltd"},
  {value: "Engage Credit Limited", label: "Engage Credit Limited"},
  {value: "First Active Mortgages", label: "First Active Mortgages"},
  {value: "First Commercial Bank Ltd ", label: "First Commercial Bank Ltd "},
  {value: "First Direct Bank", label: "First Direct Bank"},
  {value: "First National Home Finance", label: "First National Home Finance"},
  {value: "First Trust Bank", label: "First Trust Bank"},
  {value: "Furness Building Society", label: "Furness Building Society"},
  {value: "Future Mortgages", label: "Future Mortgages"},
  {value: "GE Money Home Lending Ltd", label: "GE Money Home Lending Ltd"},
  {value: "GMAC", label: "GMAC"},
  {value: "Godiva Mortgages Ltd", label: "Godiva Mortgages Ltd"},
  {value: "Halifax plc", label: "Halifax plc"},
  {value: "Hampshire Trust plc", label: "Hampshire Trust plc"},
  {value: "Handelsbanken", label: "Handelsbanken"},
  {value: "Hanley Economic Building Society", label: "Hanley Economic Building Society"},
  {value: "Harpenden Building Society", label: "Harpenden Building Society"},
  {value: "HBOS plc", label: "HBOS plc"},
  {value: "Hinckley and Rugby Building Society", label: "Hinckley and Rugby Building Society"},
  {value: "Hodge Lifetime", label: "Hodge Lifetime"},
  {value: "Holmesdale Building Society", label: "Holmesdale Building Society"},
  {value: "HSBC Bank plc", label: "HSBC Bank plc"},
  {value: "I Group Mortgages Ltd", label: "I Group Mortgages Ltd"},
  {value: "ING Direct N.V.", label: "ING Direct N.V."},
  {value: "Intelligent Finance", label: "Intelligent Finance"},
  {value: "Ipswich Building Society", label: "Ipswich Building Society"},
  {value: "ITL Mortgages Ltd", label: "ITL Mortgages Ltd"},
  {value: "JPMorgan Chase Bank, N.A.", label: "JPMorgan Chase Bank, N.A."},
  {value: "Just Retirement Ltd", label: "Just Retirement Ltd"},
  {value: "Kensington Mortgages", label: "Kensington Mortgages"},
  {value: "Kent Reliance", label: "Kent Reliance"},
  {value: "Landmark Mortgages Limited", label: "Landmark Mortgages Limited"},
  {value: "Leeds and Holbeck plc", label: "Leeds and Holbeck plc"},
  {value: "Leeds Building Society", label: "Leeds Building Society"},
  {value: "Leek United Building Society", label: "Leek United Building Society"},
  {value: "Legal & General Mortgage Club", label: "Legal & General Mortgage Club"},
  {value: "Lloyds Bank plc", label: "Lloyds Bank plc"},
  {value: "Lloyds TSB Mortgages", label: "Lloyds TSB Mortgages"},
  {value: "Lloyds TSB Scotland plc", label: "Lloyds TSB Scotland plc"},
  {value: "London Scottish Bank plc", label: "London Scottish Bank plc"},
  {value: "Loughborough Mortgages", label: "Loughborough Mortgages"},
  {value: "LV Equity Release Limited", label: "LV Equity Release Limited"},
  {value: "Manchester Building Society", label: "Manchester Building Society"},
  {value: "Mansfield Building Society", label: "Mansfield Building Society"},
  {value: "Market Harborough Building Society", label: "Market Harborough Building Society"},
  {value: "Market Harborough Mortgages Ltd", label: "Market Harborough Mortgages Ltd"},
  {value: "Mars Capital Finance Ltd", label: "Mars Capital Finance Ltd"},
  {value: "Marsden Building Society", label: "Marsden Building Society"},
  {value: "Masthaven", label: "Masthaven"},
  {value: "MBS Lending Ltd", label: "MBS Lending Ltd"},
  {value: "Melton Mowbray Building Society", label: "Melton Mowbray Building Society"},
  {value: "Metro Bank plc", label: "Metro Bank plc"},
  {value: "Monmouthshire Building Society", label: "Monmouthshire Building Society"},
  {value: "More 2 Life", label: "More 2 Life"},
  {value: "Morgan Stanley Bank Ltd", label: "Morgan Stanley Bank Ltd"},
  {value: "Mortgage Agency Services Number Five", label: "Mortgage Agency Services Number Five"},
  {value: "Mortgage Agency Services Number Four", label: "Mortgage Agency Services Number Four"},
  {value: "Mortgage Agency Services Number One", label: "Mortgage Agency Services Number One"},
  {value: "Mortgage Agency Services Number Six", label: "Mortgage Agency Services Number Six"},
  {value: "Mortgage Agency Services Number Three", label: "Mortgage Agency Services Number Three"},
  {value: "Mortgage Agency Services Number Two", label: "Mortgage Agency Services Number Two"},
  {value: "Mortgage Express Ltd", label: "Mortgage Express Ltd"},
  {value: "Mortgage Trust plc", label: "Mortgage Trust plc"},
  {value: "Mortgages One Ltd", label: "Mortgages One Ltd"},
  {value: "Mortgages plc", label: "Mortgages plc"},
  {value: "National Counties Building Society", label: "National Counties Building Society"},
  {value: "Nationwide Building Society", label: "Nationwide Building Society"},
  {value: "NatWest Bank", label: "NatWest Bank"},
  {value: "Nedbank Private Wealth Limited", label: "Nedbank Private Wealth Limited"},
  {value: "New Life Mortgages Ltd", label: "New Life Mortgages Ltd"},
  {value: "Newbury Building Society", label: "Newbury Building Society"},
  {value: "Newcastle Building Society", label: "Newcastle Building Society"},
  {value: "No1 CopperPot Credit Union", label: "No1 CopperPot Credit Union"},
  {value: "North Yorkshire Mortgages Ltd", label: "North Yorkshire Mortgages Ltd"},
  {value: "Northern Rock Asset Management plc (NRAM)", label: "Northern Rock Asset Management plc (NRAM)"},
  {value: "Norwich & Peterborough Building Society", label: "Norwich & Peterborough Building Society"},
  {value: "Nottingham Building Society", label: "Nottingham Building Society"},
  {value: "Oakwood Homeloans Ltd", label: "Oakwood Homeloans Ltd"},
  {value: "One Savings Bank plc", label: "One Savings Bank plc"},
  {value: "Paratus", label: "Paratus"},
  {value: "Penrith Building Society", label: "Penrith Building Society"},
  {value: "Pepper Mortgages", label: "Pepper Mortgages"},
  {value: "Pink Home Loans", label: "Pink Home Loans"},
  {value: "Platform Funding Ltd", label: "Platform Funding Ltd"},
  {value: "PMS", label: "PMS"},
  {value: "Post Office", label: "Post Office"},
  {value: "Precise Mortgages", label: "Precise Mortgages"},
  {value: "Preferred Mortgages Ltd", label: "Preferred Mortgages Ltd"},
  {value: "Principality Building Society", label: "Principality Building Society"},
  {value: "Progressive Building Society", label: "Progressive Building Society"},
  {value: "Prudential Banking plc", label: "Prudential Banking plc"},
  {value: "Redstone Mortgages Ltd", label: "Redstone Mortgages Ltd"},
  {value: "Rothschild", label: "Rothschild"},
  {value: "Royal Bank of Scotland plc", label: "Royal Bank of Scotland plc"},
  {value: "Saffron Building Society", label: "Saffron Building Society"},
  {value: "Santander UK plc", label: "Santander UK plc"},
  {value: "Scottish Building Society", label: "Scottish Building Society"},
  {value: "Scottish Widows Bank plc", label: "Scottish Widows Bank plc"},
  {value: "Shawbrook Bank Ltd", label: "Shawbrook Bank Ltd"},
  {value: "Shepshed Building Society", label: "Shepshed Building Society"},
  {value: "Skipton Building Society", label: "Skipton Building Society"},
  {value: "Southern Pacific Mortgage Lending", label: "Southern Pacific Mortgage Lending"},
  {value: "St James's Place Bank", label: "St James's Place Bank"},
  {value: "Stafford Railway Building Society", label: "Stafford Railway Building Society"},
  {value: "Standard Life Assurance Company", label: "Standard Life Assurance Company"},
  {value: "Standard Life Bank Ltd", label: "Standard Life Bank Ltd"},
  {value: "State Bank of India UK", label: "State Bank of India UK"},
  {value: "Stonehaven UK Ltd", label: "Stonehaven UK Ltd"},
  {value: "Svenska Handelsbanken Ab plc", label: "Svenska Handelsbanken Ab plc"},
  {value: "Swansea Building Society", label: "Swansea Building Society"},
  {value: "Teachers Building Society", label: "Teachers Building Society"},
  {value: "Tesco Personal Finance plc", label: "Tesco Personal Finance plc"},
  {value: "The Bank of Asia Ltd", label: "The Bank of Asia Ltd"},
  {value: "The Co-operative Bank plc", label: "The Co-operative Bank plc"},
  {value: "The Mortgage Business", label: "The Mortgage Business"},
  {value: "The Mortgage Works", label: "The Mortgage Works"},
  {value: "The One Account Ltd", label: "The One Account Ltd"},
  {value: "The Woolwich", label: "The Woolwich"},
  {value: "Tipton & Coseley Building Society", label: "Tipton & Coseley Building Society"},
  {value: "Tiuta plc", label: "Tiuta plc"},
  {value: "TSB", label: "TSB"},
  {value: "UBS Ltd", label: "UBS Ltd"},
  {value: "UCB Home Loans Corporation Ltd", label: "UCB Home Loans Corporation Ltd"},
  {value: "Ulster Bank Ltd", label: "Ulster Bank Ltd"},
  {value: "Vernon Building Society", label: "Vernon Building Society"},
  {value: "Verso Limited", label: "Verso Limited"},
  {value: "Virgin Money plc", label: "Virgin Money plc"},
  {value: "Wave Lending", label: "Wave Lending"},
  {value: "West Bromwich Building Society", label: "West Bromwich Building Society"},
  {value: "Yorkshire Bank", label: "Yorkshire Bank"},
  {value: "Yorkshire Bank Homeloans Ltd", label: "Yorkshire Bank Homeloans Ltd"},
  {value: "Yorkshire Building Society", label: "Yorkshire Building Society"},
]

export const feeTypes = [
  {value: "broker", label: "Broker"},
  {value: "lender", label: "Lender"},
  {value: "arrangement", label: "Arrangement"},
  {value: "booking", label: "Booking"},
  {value: "valuation", label: "Valuation"},
  {value: "legal", label: "Legal"},
  {value: "higherLendingCharge", label: "Higher Lending Charge"},
  {value: "transaction", label: "Transaction"},
  {value: "exit", label: "Exit Administration"}
];

export const loanPurposes = {
  consolidation: "Consolidation",
  homeImprovements: "Home Improvements",
  mortgage: "Mortgage",
  holiday: "Holiday",
  wedding: "Wedding",
  vehicle: "Vehicle",
  christmas: "Christmas",
  business: "Business",
  divorceSettlement: "Divorce Settlement",
  otherPropertyPurchase: "Other Property Purchase",
  redeemHTBEquityLoan: "Redeem HTB / Equity Loan",
  schoolFees: "School Fees",
  switchToBetterRate: "Switch To Better Rate",
  taxBill: "Tax Bill"
}

export const countries = [
  {code: "GBR", country: "United Kingdom"},
  {code: "ABW", country: "Aruba"},
  {code: "AFG", country: "Afghanistan"},
  {code: "AGO", country: "Angola"},
  {code: "AIA", country: "Anguilla"},
  {code: "ALA", country: "Åland Islands"},
  {code: "ALB", country: "Albania"},
  {code: "AND", country: "Andorra"},
  {code: "ARE", country: "United Arab Emirates"},
  {code: "ARG", country: "Argentina"},
  {code: "ARM", country: "Armenia"},
  {code: "ASM", country: "American Samoa"},
  {code: "ATA", country: "Antarctica"},
  {code: "ATF", country: "French Southern Territories"},
  {code: "ATG", country: "Antigua and Barbuda"},
  {code: "AUS", country: "Australia"},
  {code: "AUT", country: "Austria"},
  {code: "AZE", country: "Azerbaijan"},
  {code: "BDI", country: "Burundi"},
  {code: "BEL", country: "Belgium"},
  {code: "BEN", country: "Benin"},
  {code: "BES", country: "Bonaire, Sint Eustatius and Saba"},
  {code: "BFA", country: "Burkina Faso"},
  {code: "BGD", country: "Bangladesh"},
  {code: "BGR", country: "Bulgaria"},
  {code: "BHR", country: "Bahrain"},
  {code: "BHS", country: "Bahamas"},
  {code: "BIH", country: "Bosnia and Herzegovina"},
  {code: "BLM", country: "Saint Barthélemy"},
  {code: "BLR", country: "Belarus"},
  {code: "BLZ", country: "Belize"},
  {code: "BMU", country: "Bermuda"},
  {code: "BOL", country: "Bolivia"},
  {code: "BRA", country: "Brazil"},
  {code: "BRB", country: "Barbados"},
  {code: "BRN", country: "Brunei Darussalam"},
  {code: "BTN", country: "Bhutan"},
  {code: "BVT", country: "Bouvet Island"},
  {code: "BWA", country: "Botswana"},
  {code: "CAF", country: "Central African Republic"},
  {code: "CAN", country: "Canada"},
  {code: "CCK", country: "Cocos (Keeling) Islands"},
  {code: "CHE", country: "Switzerland"},
  {code: "CHL", country: "Chile"},
  {code: "CHN", country: "China"},
  {code: "CIV", country: "Côte d'Ivoire"},
  {code: "CMR", country: "Cameroon"},
  {code: "COD", country: "DR Congo"},
  {code: "COG", country: "Congo"},
  {code: "COK", country: "Cook Islands"},
  {code: "COL", country: "Colombia"},
  {code: "COM", country: "Comoros"},
  {code: "CPV", country: "Cabo Verde"},
  {code: "CRI", country: "Costa Rica"},
  {code: "CUB", country: "Cuba"},
  {code: "CUW", country: "Curaçao"},
  {code: "CXR", country: "Christmas Island"},
  {code: "CYM", country: "Cayman Islands"},
  {code: "CYP", country: "Cyprus"},
  {code: "CZE", country: "Czechia"},
  {code: "DEU", country: "Germany"},
  {code: "DJI", country: "Djibouti"},
  {code: "DMA", country: "Dominica"},
  {code: "DNK", country: "Denmark"},
  {code: "DOM", country: "Dominican Republic"},
  {code: "DZA", country: "Algeria"},
  {code: "ECU", country: "Ecuador"},
  {code: "EGY", country: "Egypt"},
  {code: "ERI", country: "Eritrea"},
  {code: "ESH", country: "Western Sahara"},
  {code: "ESP", country: "Spain"},
  {code: "EST", country: "Estonia"},
  {code: "ETH", country: "Ethiopia"},
  {code: "FIN", country: "Finland"},
  {code: "FJI", country: "Fiji"},
  {code: "FLK", country: "Falkland Islands"},
  {code: "FRA", country: "France"},
  {code: "FRO", country: "Faroe Islands"},
  {code: "FSM", country: "Micronesia"},
  {code: "GAB", country: "Gabon"},
  {code: "GEO", country: "Georgia"},
  {code: "GGY", country: "Guernsey"},
  {code: "GHA", country: "Ghana"},
  {code: "GIB", country: "Gibraltar"},
  {code: "GIN", country: "Guinea"},
  {code: "GLP", country: "Guadeloupe"},
  {code: "GMB", country: "Gambia"},
  {code: "GNB", country: "Guinea-Bissau"},
  {code: "GNQ", country: "Equatorial Guinea"},
  {code: "GRC", country: "Greece"},
  {code: "GRD", country: "Grenada"},
  {code: "GRL", country: "Greenland"},
  {code: "GTM", country: "Guatemala"},
  {code: "GUF", country: "French Guiana"},
  {code: "GUM", country: "Guam"},
  {code: "GUY", country: "Guyana"},
  {code: "HKG", country: "Hong Kong"},
  {code: "HMD", country: "Heard Island and McDonald Islands"},
  {code: "HND", country: "Honduras"},
  {code: "HRV", country: "Croatia"},
  {code: "HTI", country: "Haiti"},
  {code: "HUN", country: "Hungary"},
  {code: "IDN", country: "Indonesia"},
  {code: "IMN", country: "Isle of Man"},
  {code: "IND", country: "India"},
  {code: "IOT", country: "British Indian Ocean Territory"},
  {code: "IRL", country: "Ireland"},
  {code: "IRN", country: "Iran"},
  {code: "IRQ", country: "Iraq"},
  {code: "ISL", country: "Iceland"},
  {code: "ISR", country: "Israel"},
  {code: "ITA", country: "Italy"},
  {code: "JAM", country: "Jamaica"},
  {code: "JEY", country: "Jersey"},
  {code: "JOR", country: "Jordan"},
  {code: "JPN", country: "Japan"},
  {code: "KAZ", country: "Kazakhstan"},
  {code: "KEN", country: "Kenya"},
  {code: "KGZ", country: "Kyrgyzstan"},
  {code: "KHM", country: "Cambodia"},
  {code: "KIR", country: "Kiribati"},
  {code: "KNA", country: "Saint Kitts and Nevis"},
  {code: "KOR", country: "South Korea"},
  {code: "KWT", country: "Kuwait"},
  {code: "LAO", country: "Lao People's Democratic Republic"},
  {code: "LBN", country: "Lebanon"},
  {code: "LBR", country: "Liberia"},
  {code: "LBY", country: "Libya"},
  {code: "LCA", country: "Saint Lucia"},
  {code: "LIE", country: "Liechtenstein"},
  {code: "LKA", country: "Sri Lanka"},
  {code: "LSO", country: "Lesotho"},
  {code: "LTU", country: "Lithuania"},
  {code: "LUX", country: "Luxembourg"},
  {code: "LVA", country: "Latvia"},
  {code: "MAC", country: "Macao"},
  {code: "MAF", country: "Saint Martin"},
  {code: "MAR", country: "Morocco"},
  {code: "MCO", country: "Monaco"},
  {code: "MDA", country: "Moldova"},
  {code: "MDG", country: "Madagascar"},
  {code: "MDV", country: "Maldives"},
  {code: "MEX", country: "Mexico"},
  {code: "MHL", country: "Marshall Islands"},
  {code: "MKD", country: "North Macedonia"},
  {code: "MLI", country: "Mali"},
  {code: "MLT", country: "Malta"},
  {code: "MMR", country: "Myanmar"},
  {code: "MNE", country: "Montenegro"},
  {code: "MNG", country: "Mongolia"},
  {code: "MNP", country: "Northern Mariana Islands"},
  {code: "MOZ", country: "Mozambique"},
  {code: "MRT", country: "Mauritania"},
  {code: "MSR", country: "Montserrat"},
  {code: "MTQ", country: "Martinique"},
  {code: "MUS", country: "Mauritius"},
  {code: "MWI", country: "Malawi"},
  {code: "MYS", country: "Malaysia"},
  {code: "MYT", country: "Mayotte"},
  {code: "NAM", country: "Namibia"},
  {code: "NCL", country: "New Caledonia"},
  {code: "NER", country: "Niger"},
  {code: "NFK", country: "Norfolk Island"},
  {code: "NGA", country: "Nigeria"},
  {code: "NIC", country: "Nicaragua"},
  {code: "NIU", country: "Niue"},
  {code: "NLD", country: "Netherlands"},
  {code: "NOR", country: "Norway"},
  {code: "NPL", country: "Nepal"},
  {code: "NRU", country: "Nauru"},
  {code: "NZL", country: "New Zealand"},
  {code: "OMN", country: "Oman"},
  {code: "PAK", country: "Pakistan"},
  {code: "PAN", country: "Panama"},
  {code: "PCN", country: "Pitcairn"},
  {code: "PER", country: "Peru"},
  {code: "PHL", country: "Philippines"},
  {code: "PLW", country: "Palau"},
  {code: "PNG", country: "Papua New Guinea"},
  {code: "POL", country: "Poland"},
  {code: "PRI", country: "Puerto Rico"},
  {code: "PRK", country: "North Korea"},
  {code: "PRT", country: "Portugal"},
  {code: "PRY", country: "Paraguay"},
  {code: "PSE", country: "Palestine"},
  {code: "PYF", country: "French Polynesia"},
  {code: "QAT", country: "Qatar"},
  {code: "REU", country: "Réunion"},
  {code: "ROU", country: "Romania"},
  {code: "RUS", country: "Russian Federation"},
  {code: "RWA", country: "Rwanda"},
  {code: "SAU", country: "Saudi Arabia"},
  {code: "SDN", country: "Sudan"},
  {code: "SEN", country: "Senegal"},
  {code: "SGP", country: "Singapore"},
  {code: "SGS", country: "South Georgia and the South Sandwich Islands"},
  {code: "SHN", country: "Saint Helena, Ascension and Tristan da Cunha"},
  {code: "SJM", country: "Svalbard and Jan Mayen"},
  {code: "SLB", country: "Solomon Islands"},
  {code: "SLE", country: "Sierra Leone"},
  {code: "SLV", country: "El Salvador"},
  {code: "SMR", country: "San Marino"},
  {code: "SOM", country: "Somalia"},
  {code: "SPM", country: "Saint Pierre and Miquelon"},
  {code: "SRB", country: "Serbia"},
  {code: "SSD", country: "South Sudan"},
  {code: "STP", country: "Sao Tome and Principe"},
  {code: "SUR", country: "Suriname"},
  {code: "SVK", country: "Slovakia"},
  {code: "SVN", country: "Slovenia"},
  {code: "SWE", country: "Sweden"},
  {code: "SWZ", country: "Eswatini"},
  {code: "SXM", country: "Sint Maarten"},
  {code: "SYC", country: "Seychelles"},
  {code: "SYR", country: "Syrian Arab Republic"},
  {code: "TCA", country: "Turks and Caicos Islands"},
  {code: "TCD", country: "Chad"},
  {code: "TGO", country: "Togo"},
  {code: "THA", country: "Thailand"},
  {code: "TJK", country: "Tajikistan"},
  {code: "TKL", country: "Tokelau"},
  {code: "TKM", country: "Turkmenistan"},
  {code: "TLS", country: "Timor-Leste"},
  {code: "TON", country: "Tonga"},
  {code: "TTO", country: "Trinidad and Tobago"},
  {code: "TUN", country: "Tunisia"},
  {code: "TUR", country: "Turkey"},
  {code: "TUV", country: "Tuvalu"},
  {code: "TWN", country: "Taiwan"},
  {code: "TZA", country: "Tanzania"},
  {code: "UGA", country: "Uganda"},
  {code: "UKR", country: "Ukraine"},
  {code: "UMI", country: "United States Minor Outlying Islands"},
  {code: "URY", country: "Uruguay"},
  {code: "USA", country: "United States of America"},
  {code: "UZB", country: "Uzbekistan"},
  {code: "VAT", country: "Holy See"},
  {code: "VCT", country: "Saint Vincent and the Grenadines"},
  {code: "VEN", country: "Venezuela"},
  {code: "VGB", country: "Virgin Islands (British)"},
  {code: "VIR", country: "Virgin Islands (US)"},
  {code: "VNM", country: "Viet Nam"},
  {code: "VUT", country: "Vanuatu"},
  {code: "WLF", country: "Wallis and Futuna"},
  {code: "WSM", country: "Samoa"},
  {code: "YEM", country: "Yemen"},
  {code: "ZAF", country: "South Africa"},
  {code: "ZMB", country: "Zambia"},
  {code: "ZWE", country: "Zimbabwe"}
];
