<script>
    import { router } from "tinro";
    import {activeCase, activePortal} from "../../store";
    $: isProtect = $activeCase.portal.api === 'protect';
    $: myTab = $activePortal.term != undefined ? $activePortal.term : "Case";
</script>

<div class="navigation">
    <nav>
        <a class="button" href="/" class:active={$router.path === "/"}>My { myTab }</a>
        {#if !isProtect}
            <a class="button" href="/todo" class:active={$router.path === "/todo"}>To-Do List</a>
        {/if}
        <a class="button" href="/documents" class:active={$router.path === "/documents"}>Documents</a>
    </nav>
</div>

<style>

    nav {
        margin: 5rem 6rem 5rem 2rem;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        justify-content: start;
        gap: 1rem;
        z-index:100;
    }

    .button {
        white-space: nowrap;
        width:100%;
    }

    @media(max-width: 850px) {
        .navigation {
            border-top: 1px solid #cccccc;
        }
        nav {
            padding:0.5rem;
            margin: 0px 2rem;
            flex-direction: row;
            justify-content: space-evenly;
            justify-items: center;
            align-items: center;
        }

        nav > a {
            display:flex;
            flex-grow: 1;
            align-items: center;
            justify-content: center;
        }

    }

    @media(max-width: 500px) {
        .button.active {
            display:none;
        }
        .sidebar.protection-sidebar nav .button:not(:first-child) {
            display:none;
        }
    }

</style>