<script>

  import {getContext, onMount} from 'svelte';

  import Quote from '../Components/Quote.svelte';
  import Filter from '../Components/Filter.svelte';
  import {productValues} from "../../../lists";
  import CallFloatingCTA from "../Components/CallFloatingCTA.svelte";

  // From Context
  const accessCode = getContext('accessCode');
  const portal = getContext('portal');

  // Passed Vars
  export let quote;
  export let productQuote;
  export let selectedQuoteType;
  export let filters;
  export let sort;

  // Tabs/Quotes
  let tabs = [];
  let transferQuotes = null;
  let bestQuotes = null
  let consolidationQuotes = null;

  // Computed
  $: unsecuredDebt = quote ? Math.max(quote.quotes.filter(x => x.unsecuredDebt).map(x => x.unsecuredDebt)) : 0;
  $: creditors = consolidationQuotes ? consolidationQuotes.creditors : 0;
  $: noFilteredQuotes = filters.length > 0 && tabs.filter(x => (x.quote.products ? x.quote.products : []).length > 0).length === 0;
  $: isSecondCharge = portal === "second-charge";

  // Watch
  $: {
    let transferQuotes = quote ? getQuotes("productTransfer", filters) : null;
    let bestQuotes = quote ? getQuotes("customerRequest", filters) : null
    consolidationQuotes = quote ? getQuotes("consolidationExample", filters) : null;
    tabs = [
      transferQuotes && transferQuotes.products.length ? {
        label: "Your current lender",
          subLabel: "Move to a new product",
        type: "current",
        quote: transferQuotes
      } : null,
      bestQuotes && bestQuotes.products.length ? {
        label: transferQuotes && transferQuotes.products.length ? "Top deals from other lenders" : "Top deals from our lending panel",
        type: "top",
        quote: bestQuotes
      } : null,
      consolidationQuotes && consolidationQuotes.products.length ? {
        label: "Customised offers to save you money",
        type: "consolidation",
        quote: consolidationQuotes
      } : null
    ].filter(x => x).filter(x => x.quote.quoteType === selectedQuoteType);
  }

  $: hasRetentionQuote = tabs.find(x => x.type === "current") != null;

  function getQuotes(quoteType, filters) {
    let q = quote.quotes.find(x => x.quoteType === quoteType);
    if (q == null) return null;
    if (filters.length > 0) {
      let filterRates = productValues.filter(p => filters.includes(p.value)).map(x => x.rate);
      q = {
        ...q,
        products: q.products
          .filter(p =>
            p.rates.length > 0
            &&
            filterRates.filter(x =>
              x.type === p.rates[0].type
              &&
              ((typeof x.minTerm === "undefined" || p.rates[0].term >= x.minTerm) && (typeof x.maxTerm === "undefined" || p.rates[0].term <= x.maxTerm))
            ).length > 0)
      };
    }
    if (sort) {
      q = {
        ...q,
        products: q.products.sort((a, b) => {
          let compare1 = 0;
          let compare2 = 0;

          if (sort.by === "rate") {
            compare1 = a.rates[0].rate;
            compare2 = b.rates[0].rate;
          } else if (sort.by === "fee") {
            compare1 = a.fees.reduce((x, y) => x + y.amount, 0);
            compare2 = b.fees.reduce((x, y) => x + y.amount, 0);
          } else if (sort.by === "total") {
            compare1 = a.rates.reduce((x, y) => x + (y.monthlyRepayment * y.term), 0);
            compare2 = b.rates.reduce((x, y) => x + (y.monthlyRepayment * y.term), 0);
          } else if (sort.by === "aprc") {
            compare1 = a.aprc;
            compare2 = b.aprc;
          } else {
            compare1 = a.rates[0].monthlyRepayment;
            compare2 = b.rates[0].monthlyRepayment;
          }

          if (sort.descending) {
            return (compare2 ? compare2 : 0) - (compare1 ? compare1 : 0);
          } else {
            return (compare1 ? compare1 : 0) - (compare2 ? compare2 : 0);
          }
        })
      };
    }
    return q;
  }

</script>

<div class="auto-aip__quoted">

    <CallFloatingCTA />

    <div class="auto-aip__quoted__header">
        <button
                class="button fill mt"
                class:orange={!isSecondCharge}
                on:click={() => selectedQuoteType = null }>
            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="16" viewBox="0 0 34.385 54.503">
                <g transform="translate(-2720.916 -2675.51)">
                    <path d="M2755.3,2675.51v3.3l-32.456,23.956,32.456,23.958v3.293l-34.385-25.6v-3.295Z" fill="#ffffff"
                          stroke="#fff" stroke-width="3px"/>
                </g>
            </svg>
            Back
        </button>
        <Filter
                bind:quote={quote}
                on:updateQuote
                bind:filters
                bind:sort
        />
    </div>

    <div class="auto-aip__quoted__info">

        {#if noFilteredQuotes}
            <div class="auto-aip__quoted__filter__noquotes">
                <h2>No quotes were found matching your selection.</h2>
                <h3>Please adjust your filter.</h3>
            </div>
        {:else}
            {#each tabs as tab, tabIndex}
                <h2>{tab.label}</h2>
                {#if tab.subLabel}
                    <h3>{tab.subLabel}</h3>
                {/if}
                {#each tab.quote.products as product, index}
                    <div class="auto-aip__quoted__info__box" class:first={index === 0}>
                        <Quote
                                bind:product={product}
                                bind:quote={tab.quote}
                                bind:mainquote={quote}
                                bind:productQuote={productQuote}
                                on:selected={(s) => { selectedQuoteType = s.detail }}
                        />
                    </div>
                {/each}
            {/each}
        {/if}
    </div>

</div>

<style>

    .auto-aip__quoted__header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: left;
        gap: 0.5rem;
    }

    .auto-aip__quoted__header button {
        flex-grow: 1;
    }

    .auto-aip__quoted hr {
        width: 100%;
        height: 2px;
        background-color: #bfe5ee;
        border: none;
        margin-top: 2rem;
    }

    .auto-aip__quoted {
        margin: 0 auto;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 2rem;
    }

    .auto-aip__quoted__info {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: center;
        gap: 0.5rem;
        width: 100%;
        text-align: left;
        padding-top: 1rem;
    }

    .auto-aip__quoted__info h2, .auto-aip__quoted__info h3 {
        margin: 0;
        color:white;
    }


    .auto-aip__quoted__info {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: center;
        gap: 0.5rem;
        width: 100%;
        text-align: center;
    }

    .auto-aip__quoted__info .auto-aip__quoted__info__box h2 {
        margin: 0;
        padding: 0;
    }

    .auto-aip__quoted__info__box {
        background-color: white;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 0.75rem;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
        text-align: center;
    }
    .auto-aip__quoted__info__box.first {
        border: 2px solid #01b57a;
    }

    .auto-aip__quoted__info__box h3 {
        padding: 0;
        margin: 0;
    }

    .auto-aip__quoted__filter__quote-shortcut {
        display: flex;
    }

    .auto-aip__quoted__filter__quote-shortcut svg {
        display: flex;
        align-items: center;
        padding: 0 1rem 0 0.5rem;
        margin: 0 1rem 0 0;
        border-right: 1px solid rgba(255, 255, 255, 1);
    }


    .other__quotes {
        gap: 0.5rem;
        display: flex;
        flex-direction: column;
    }

    .other__quotes .auto-aip__quoted__info__box {
        border-color: #f2f2f2;
    }

    .other__quotes .auto-aip__quoted__info__box:hover {
        border-color: #bfe5ee;
    }

    .auto-aip__quoted__arrow {
        color: #bfe5ee;
        font-size: 4rem;
    }

    .auto-aip__quoted__other__summary {
        margin-bottom: 1rem;
    }

    .auto-aip__quoted__other__summary p {
        padding: 0.5rem 1rem;
        margin: 0;
        text-align: left;
    }

    .auto-aip__quoted__filter__noquotes {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 2rem;
    }

    @media (max-width: 1500px) {
        .auto-aip__quoted__info {
            width: 100%;
        }
    }

    @media (max-width: 1200px) {
        .auto-aip__quoted__info {
            flex-direction: column;
        }
    }

    @media (max-width: 850px) {
        .auto-aip__quoted {
            width: 100%;
        }

        .auto-aip__quoted__info__box {
            padding: 1rem;
        }
    }

    @media (max-width: 500px) {
        .auto-aip__tabs {
            flex-direction: column;
        }
    }

</style>
