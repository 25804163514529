<script>
  import api from "../../api";
  import {activeCase} from "../../store";
  import {countries} from "../../lists";

  export let detail;

  let error = false;
  let working = false;
  let redirecting = false;
  let smartSearchParams = {
    type: "driving_licence",
    issuingCountry: "GBR"
  }

  async function executeSmartSearch() {
    working = true;
    let result;
    if (typeof detail.uri === "undefined") {
      result = await api.smartSearch(detail.id, smartSearchParams);
    } else {
      result = {redirect: detail.uri};
    }
    if (result.redirect) {
      window.location.assign(result.redirect);
      redirecting = true;
      working = false;
    } else {
      redirecting = false;
      working = false;
      error = true;
    }
  }
</script>

<div>
    <div class="smart-search">
        <div class="smart-search__form">
            <div class="smart-search__form__item">
                <label>Document:</label>
                <select class="active" bind:value={smartSearchParams.type}>
                    <option value="driving_licence">Driving Licence</option>
                    <option value="passport">Passport</option>
                </select>
            </div>
            <div class="smart-search__form__item">
                <label>Country Issued:</label>
                <select class="active" bind:value={smartSearchParams.issuingCountry}>
                    {#each countries as country}
                        <option value="{country.code}">{country.country}</option>
                    {/each}
                </select>
            </div>
        </div>
        {#if error}
            <div class="smart-search__error">
                <p>There was problem processing your request, if you continue to receive message then please contact
                    us.</p>
            </div>
        {/if}
        <button class="button green small" on:click={async () => executeSmartSearch()} disabled={working}>
            <span>{ redirecting ? "Redirecting..." : working ? "Please Wait..." : "Confirm Identity" }</span>
            <span class="working" class:show={working || redirecting}>
                <svg
                        class="loading"
                        viewBox="0 0 100 100"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.5em"
                        width="1.5em"
                >
                    <circle
                            cx="50"
                            cy="50"
                            r="40"
                            stroke="white"
                            stroke-width="10"
                            fill="none"
                            stroke-dasharray="226.194 226.194"
                    />
                </svg>
            </span>
        </button>
        <p class="smart-search__privacy">
            Our identity check is powered by SmartSearch, an external service. You can view their privacy policy
            at <a href="https://www.smartsearch.com/privacy-policy" target="_blank">https://www.smartsearch.com/privacy-policy</a>.
        </p>
    </div>
</div>

<style>

    .button {
        display: flex;
        align-items: center;
    }

    .smart-search {
        margin-top: 0.5rem;
    }

    .smart-search__error p {
        padding: 0;
        margin: 0;
    }

    .smart-search__form {
        padding: 0;
    }

    .smart-search__form__item {
        display: flex;
        align-items: center;
        align-content: start;
        margin: 0.25rem 0;
    }

    .smart-search__form__item label {
        font-size: 0.8em;
        min-width: 110px;
        display: block;
        white-space: nowrap;
        word-wrap: normal;
    }

    .smart-search__form__item select {
        width: 100%;
        padding: 3px;
    }

    .smart-search__confirm {
        display: flex;
        font-size: 0.6em;
    }

    .smart-search__error p {
        font-size: 0.8em;
        padding-bottom: 0.5rem;
    }

    .smart-search__privacy {
        font-size: 0.8em;
    }

    @media screen and (max-width: 767px) {
        .smart-search__form__item label {
            min-width: initial;
        }

        .smart-search__form__item {
            flex-direction: column;
            align-items: start;
        }

    }

    .working {
        display: none;
    }

    .working.show {
        display: flex;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .working > svg {
        animation: rotating 2s linear infinite;
        margin-left: 0.5em;
    }

</style>
