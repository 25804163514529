<script>

    import {getContext, createEventDispatcher, onMount} from 'svelte';

    // Dispatcher
    const dispatch = createEventDispatcher();

    // From Context
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    // Passed Vars
    export let quote= null;
    export let product= null;
    export let productQuote = null;
    export let mainquote = null;
    export let primary = false;
    export let completed = false;

    // Computed Properties
    $: loanAmount = product.loanAmount || 0;
    $: term = product.rates && product.rates.length ? product.rates.reduce((a, b) => a + b.term, 0) : null;
    $: initialMonthlyPayment = product.rates && product.rates.length ? product.rates[0].monthlyRepayment : null;
    $: initialRate = product.rates && product.rates.length ? product.rates[0].rate : null;
    $: productName = product.name && product.name.length ? product.name : "N/A";
    $: aprc = product.aprc;
    $: fees = product.fees.length ? product.fees.reduce((a, b) => a + b.amount, 0) : 0;
    $: totalRepayable = product.rates.length ? product.rates.reduce((a,b) => a + (b.monthlyRepayment * b.term), 0) : 0;
    $: netLoanInterest = totalRepayable - loanAmount - fees;
    $: onlyOneAndFixed = product.rates.length === 1 && product.rates[0].type === "Fixed";

    $: repPayments = product.rates.length ? product.rates.map((rate) => {
        return `${rate.term} instalments of £${rate.monthlyRepayment.toFixed(2)} at ${rate.rate.toFixed(2)}% (${rate.type})`
    }).join(", followed by ") : '';

    $: repExample = `Borrowing £${loanAmount.toLocaleString("en-GB", {minimumFractionDigits: 0,maximumFractionDigits: 0})} over ${term} months. You will pay
    ${repPayments}. Total amount payable of £${totalRepayable.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    This includes a lender fee of £${fees.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})} and the
    net loan interest of £${netLoanInterest.toLocaleString("en-GB", {minimumFractionDigits: 2,maximumFractionDigits: 2})}.
    The overall cost for comparison is ${aprc}% APRC ${onlyOneAndFixed ? '' : 'Variable'}.`;

</script>

<div class={`quote ${primary ? 'primary' : ''} ${completed ? 'completed' : ''}`}>
    <div class="quote__content">
        <div class="block highlight">
            <div class="img">
                <img src={product.imageUrl} alt={product.lender} />
            </div>
            <span>Initial Monthly Payment</span>
            <h2>
                &pound;{
                initialMonthlyPayment.toLocaleString("en-GB", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                })
            }
            </h2>
        </div>
        <div class="block">
            <table>
                <tr>
                    <td>Initial Rate</td>
                    <td>
                        {
                            initialRate.toLocaleString("en-GB", {
                                maximumFractionDigits: 2
                            })
                        }%
                    </td>
                </tr>
                <tr>
                    <td>Fee</td>
                    <td>&pound;{fees.toLocaleString("en-GB", { maximumFractionDigits: 2})}</td>
                </tr>
                <tr>
                    <td>Total Repayable</td>
                    <td>&pound;{
                        totalRepayable.toLocaleString("en-GB", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })
                    }</td>
                </tr>
                <tr>
                    <td>APRC</td>
                    <td>{aprc}%</td>
                </tr>
            </table>
        </div>
    </div>
    <div class="quote__footer">
        {#if mainquote.status === "quoted"}
            <button class="button green pill fill m-auto" on:click={() =>  dispatch("selected", quote.quoteType)}>View Deal</button>
        {/if}
        <p class="small">{ repExample }</p>
    </div>
</div>

<style>

    .quote {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 1rem;
    }

    .quote__content {
        min-width: 100%;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 0.5rem;
    }

    .quote__content div.img {
        max-height:100px;
        min-width: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding:0.5rem 0;
    }

    .quote__content div.block {
        display: flex;
        flex-direction: column;;
        justify-content: center;
        gap: 0.5rem;
        flex: 1;
        text-align: left;
        padding: 1rem 0;
    }

    .quote__content div.block h2 {
        padding:0;
        margin:0;
    }

    .quote__content tr td:first-child {
        font-weight: bold;
    }

    .quote__content div.block td {
        padding: 0.3rem 0;
    }

    .quote__content div.block.highlight {
        text-align: center;
    }

    .quote__footer {
        width:100%;
        text-align: center;
    }

    .quote__footer .small {
        font-size:12px;
    }

    .quote.completed {
        padding: 1rem;
    }

    .quote.completed .quote__info__image {
        display:none;
    }

    @media (max-width: 475px) {
        .quote__content tr {
            display:flex;
            flex-direction: column;
            padding:0;
            margin:0;
            border-collapse: collapse;
        }
        .quote__content td {
            font-size:14px;
            padding:0;
            margin:0;
            border-collapse: collapse;
        }
    }

</style>
