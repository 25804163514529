import {get, writable} from "svelte/store";

const clickAppDataFromSessionStorage = JSON.parse(sessionStorage.getItem("click_app_data"));
const clickAppData = clickAppDataFromSessionStorage ? {...clickAppDataFromSessionStorage} : {};
export const appData = writable(clickAppData);

function firstOrNone(item) {
  if (item && 0 in item) {
    return item[0];
  }
  return item;
}

export default async function() {
  let trackData = get(appData);
  if (location.search) {
    const params = location.search
      ? location.search
          .slice(1)
          .split("&")
          .map(p => {
            const parts = p.split("=");
            return {
              key: decodeURIComponent(parts[0]),
              value: parts.length > 1 ? decodeURIComponent(parts[1]) : ""
            };
          })
      : [];
    const query = {};
    for (const param of params) {
      if (param.key in query) {
        query[param.key].push(param.value);
      } else {
        query[param.key] = [param.value];
      }
    }
    if (
      Object.keys(query).some(
        k => k.startsWith("utm_") || k === "gclid" || k === "msclkid"
      )
    ) {
      if ("gclid" in query) {
        query["sub_id"] = query.gclid;
        delete query.gclid;
      }
      if ("msclkid" in query) {
        query["sub_id"] = query.msclkid;
        delete query.msclkid;
      }
      trackData = {
        ...trackData,
        landingURL: location.href,
        referralURL: document.referrer,
        query: query
      };
      if (!trackData.session) {
        const impression = {
          landing_url: trackData.landingURL || "",
          referral_url: trackData.referralURL || "",
          source: firstOrNone(query.utm_source),
          medium: firstOrNone(query.utm_medium),
          campaign: firstOrNone(query.utm_campaign),
          term: firstOrNone(query.utm_term),
          keyword: firstOrNone(query.utm_keyword),
          sub_id: firstOrNone(query.sub_id)
        };
        trackData.session = await fetch(
          "https://api.clickfinance.co.uk/leads/impression-web",
          {
            method: "POST",
            body: JSON.stringify(impression),
            headers: { "Content-Type": "application/json" }
          }
        )
          .then(res => res.json())
          .catch(() => null);
      }
      appData.set(trackData);
    }
  }
  return trackData;
}