
const old_apply_widget_production = "https://apply.clickfinance.co.uk/js/widget.js";
const old_apply_widget_development = "https://app-form.dev.click.tech/js/widget.js";

const bmg_apply_widget_production = "https://apply.believemoney.com/js/widget.js";
const bmg_apply_widget_development = "https://app-form-bmg.dev.click.tech/js/widget.js";

export const portals = {
    standard: {
        brand: {
            name: "Believe",
            shortName: "Believe",
            logo: "believe.png"
        }
    },
    all: [
        {
            api: "first-charge",
            term: "Mortgage",
            brand: {
                name: "Believe Finance",
                shortName: "Finance",
                logo: "believefinance.png",
                domain: "believefinance.co.uk",
                telephone: "01302 590 873"
            },
            form: {
                target: "finance",
                url: () => {
                    return isProduction ? old_apply_widget_production : old_apply_widget_development;
                },
                modes: () => {
                    return isProduction ? [
                        { mode: 'Buy To Let', leadSource: "Believe Mortgages", media: 20003 },
                        { mode: 'First Time Buyer', leadSource: "Believe Mortgages", media: 20004 },
                        { mode: 'Home Purchase', leadSource: "Believe Mortgages", media: 20005 },
                        { mode: 'Remortgage Raise', leadSource: "Believe Mortgages",media: 20007 },
                        { mode: 'Remortgage Switch', leadSource: "Believe Mortgages", media: 20006 }
                    ] : [
                        { mode: 'Buy To Let', leadSource: "Believe Mortgages", media: 20004 },
                        { mode: 'First Time Buyer', leadSource: "Believe Mortgages", media: 20004 },
                        { mode: 'Home Purchase', leadSource: "Believe Mortgages", media: 20004 },
                        { mode: 'Remortgage Raise', leadSource: "Believe Mortgages", media: 20004 },
                        { mode: 'Remortgage Switch', leadSource: "Believe Mortgages", media: 20004 }
                    ];
                },
                loan: {
                    min: 50000,
                    max: 10000000
                }
            },
            documentURLs: {
                privacyNotice: "https://www.believefinance.co.uk/privacy",
                terms: "https://www.believefinance.co.uk/terms"
            },
            trustPilot: {
                templateId: "53aa8912dec7e10d38f59f36",
                businessUnitId: "5f6b404265e7620001308f48"
            },
            slug: "f"
        },
        {
            api: "second-charge",
            term: "Loan",
            brand: {
                name: "Believe Loans",
                shortName: "Loans",
                logo: "believeloans.png",
                domain: "believeloans.com",
                telephone: "01302 590 853"
            },
            form: {
                target: "loans",
                url: () => {
                    return isProduction ? old_apply_widget_production : old_apply_widget_development;
                },
                modes: () => {
                    return isProduction ? [
                        { mode: 'Hybrid', leadSource: "Believe Loans Website", code: 20001 }
                    ] : [
                        { mode: 'Hybrid', leadSource: "Believe Loans Website", code: 20001 }
                    ];
                },
                loan: {
                    min: 5000,
                    max: 500000
                }
            },
            documentURLs: {
                privacyNotice: "https://www.believeloans.com/privacy",
                terms: "https://www.believeloans.com/terms"
            },
            trustPilot: {
                templateId: "53aa8912dec7e10d38f59f36",
                businessUnitId: "59a6e7ff0000ff0005aa092e"
            },
            slug: "s",
        },
        {
            api: "specialist-finance",
            term: "Mortgage",
            brand: {
                name: "Believe Specialist Finance",
                shortName: "Specialist",
                logo: "believespecialistfinance.png",
                domain: "believespecialistfinance.co.uk",
                telephone: "01302 238 209"
            },
            form: {
                target: "specialist",
                url: () => {
                    return isProduction ? bmg_apply_widget_production : bmg_apply_widget_development;
                },
                modes: () => {
                    return isProduction ? [
                        { mode: 'Specialist', code: 20014 }
                    ] : [
                        { mode: 'Specialist', code: 20004 }
                    ];
                },
                loan: {
                    min: 10000,
                    max: 20000000
                }
            },
            documentURLs: {
                privacyNotice: "https://www.believespecialistfinance.co.uk/privacy",
                terms: "https://www.believespecialistfinance.co.uk/terms"
            },
            trustPilot: {
                templateId: "53aa8912dec7e10d38f59f36",
                businessUnitId: "5f6b404265e7620001308f48"
            },
            slug: "g"
        },
        {
            api: "protect",
            term: "Protection",
            brand: {
                name: "Believe Protect",
                shortName: "Protect",
                logo: "believeprotect.png",
                domain: "believeprotect.co.uk",
                telephone: "01302 590 852"
            },
            form: {
                target: "protect",
                url: () => {
                    return isProduction ? bmg_apply_widget_production : bmg_apply_widget_development;
                },
                modes: () => {
                    return isProduction ? [
                        { mode: 'Protect' }
                    ] : [
                        { mode: 'Protect' }
                    ];
                },
                loan: {
                    min: 10000,
                    max: 20000000
                }
            },
            documentURLs: {
                privacyNotice: "https://www.believeprotect.co.uk/privacy",
                terms: "https://www.believeprotect.co.uk/terms"
            },
            slug: "p"
        }
    ]
}
