<script>
    import { getFormattedDateForUpload } from '../utils';
    import {createEventDispatcher, getContext} from 'svelte';
    const dispatch = createEventDispatcher();

    import api from "../api";
    import Document from "./Document.svelte";
    import {aipAccessCode} from "../utils";
    import ConfirmDialog from "./ConfirmDialog.svelte";

    export let aip = false;
    export let autoaip = false;

    let fileSelect;
    let uploadQueue = [];
    let working = false;
    let confirm = false;

    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    export let uploadName = "";
    export let uploadType = "default";
    export let basic = false
    export let button = undefined;
    export let small = false;

    function browse() {
        fileSelect.click();
    }

    const allowedTypes = new Set(["application/pdf", "image/jpeg", "image/png"]);
    let fileCounter = 0;

    function addToUploadQueue() {
        for (const file of fileSelect.files) {
            const exists = uploadQueue.some(f => f.file.name === file.name && f.file.type === file.type && f.file.size === file.size && f.file.lastModified === file.lastModified);
            if (!exists) {
                let error = null;
                if (file.size > 26214400) {
                    error = "File is larger than 25MB";
                } else if (!allowedTypes.has(file.type)) {
                    error = "File type not supported";
                }
                uploadQueue.push({
                    id: fileCounter++,
                    file: file,
                    error: error,
                    status: error || "Ready to upload",
                    name: uploadType === "default" ? null : uploadType
                });
                uploadQueue = uploadQueue;
            }
        }
    }

    function removeFromUploadQueue(index) {
        uploadQueue.splice(index, 1);
        uploadQueue = uploadQueue;
    }

    async function upload() {
        working = true;
        for (let i = 0; i < uploadQueue.length; i++) {
            const file = uploadQueue[i];
            if (file.error) {
                uploadQueue[i].status = `Skipped: ${file.error}`;
                continue;
            }
            // make filename
            let ext = file.file.name.substring(file.file.name.lastIndexOf('.') + 1);
            let fn = `${uploadType === "default" ? `Upload-${getFormattedDateForUpload()}` : uploadType}-${i}.${ext}`;

            const uploadParams = {
                file: file.file, name: fn, progress: e => {
                    if (e.type === "loadstart") {
                        uploadQueue[i].status = `Uploading...`;
                    } else if (e.type === "progress") {
                        uploadQueue[i].status = `Uploading (${(
                            (e.loaded / e.total) *
                            100.0
                        ).toLocaleString("en-GB", {maximumFractionDigits: 1})}%)`;
                    } else if (e.type === "load") {
                        uploadQueue[i].status = "Processing...";
                    } else if (e.type === "error") {
                        uploadQueue[i].status = "Failed";
                    }
                }
            }
            let result = { success: false };
            switch (true) {
                case autoaip:
                    result = await api.autoAipUpload({portal: portal, accessCode: accessCode, uploadType, ...uploadParams});
                    break;
                case aip:
                    result = await api.aipUpload({accessCode: aipAccessCode(), uploadType, ...uploadParams})
                    break;
                default:
                    result = await api.upload(uploadParams);
                    break;
            }
            if (result.doc) {
                uploadQueue[i].doc = result.doc;
            }
            uploadQueue[i].status = result.success ? "Done!" : "Failed";
        }
        const completed = uploadQueue.filter(f => f.doc);
        const failed = uploadQueue.filter(f => !f.doc);
        uploadQueue = failed;
        working = false;
        if (completed.length > 0) dispatch('uploaded', {completed});
        if (failed.length > 0) dispatch('failed', {failed});
    }

</script>

<div class="uploader {autoaip ? `auto-aip` : ``}">
    {#if uploadQueue.length}
        <div class="documents {basic ? `blocks` : ``}">
            {#each uploadQueue as doc, index (`${doc.fileName}_${index}`)}
                <Document icon={!basic} doc={doc} closable on:remove={() => removeFromUploadQueue(index)}>
                    {#if !basic}
                        <h3>{doc.file.name}</h3>
                        <p>{doc.status}</p>
                    {:else}
                        <p>{doc.file.name}</p>
                    {/if}
                </Document>
            {/each}
        </div>
    {/if}
    <div class="upload-buttons {basic ? `small` : ``}">
        {#if aip}
            <button class="button blue fill pill" on:click={() => dispatch('back')}>Back..</button>
        {/if}
        <input bind:this={fileSelect} on:change={addToUploadQueue} type="file" id="fileSelect" multiple/>
        <button class={`button fill pill ${ uploadQueue.length === 0 ? `green` : `blue`} ${small ? 'small' : ''}`} on:click={() => browse(uploadType)} disabled={working}>
            {uploadQueue.length > 0 ? `Add Another` : button ? button : uploadName ? autoaip === false ? `Add ${uploadName}` : `Select documents` : "Add Document"}
        </button>
        {#if uploadQueue.length}
            <button
                    class={`button green pill fill ${small ? 'small' : ''}`}
                    on:click="{() => { confirm = true; }}"
                    disabled={working}>Upload
            </button>
            <ConfirmDialog
                    title="Start Upload?"
                    buttonCancelText="No"
                    buttonConfirmText="Upload"
                    on:confirm={() => upload()}
                    bind:show={confirm}>
                <p>Have you added all the documents you need to upload?</p>
                <p>If not, click no and then click Add for each document you need to send us, otherwise click Upload to start uploading.</p>
            </ConfirmDialog>
        {/if}
    </div>
</div>

<style>

    .documents {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        margin-bottom: 1em;
    }

    .documents.blocks {
        display:flex;
        grid-template-columns: none;
        flex-direction: column;
        gap:0.5em;
        margin-bottom: 0.5em;
    }

    .documents h3 {
        word-break: break-all;
    }

    .documents.blocks :global(.document) {
        border:0;
        padding:0px;
    }

    input[type=file] {
        display: none;
    }

    @media screen and (max-width: 1236px) {
        .documents {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 950px) {
        .documents {
            grid-template-columns: 1fr;
        }
    }

    .uploader.auto-aip .upload-buttons {
        display: flex;
        align-items:center;
        justify-content: space-between;
        gap:0.5rem;
    }

    .uploader.auto-aip .upload-buttons .button {
        flex:1;
    }

    @media screen and (max-width: 500px) {
        .uploader.auto-aip .upload-buttons .button {
            font-size: 1rem;
        }
    }

</style>
