<script>
  import api from "../../Shared/api";
  import {onMount} from "svelte";
  import {activeCaseData} from "../../Shared/store";
  import {router} from "tinro";

  let done = false;
  activeCaseData.subscribe(async value => {
    if (done || !value) return;
    done = true;
    const id = value.todo.flatMap(a => a.needs).find(n => n.type === "smartsearch" && n.authorised).id;
    await api.returnedFromSmartSearch(id);
    sessionStorage.setItem("smartSearchComplete", "yes");
    $activeCaseData.todo = $activeCaseData.todo.map(a => ({
      ...a,
      needs: a.needs.map(n => n.id === id ? {...n, completed: true} : n)
    }));
  });

  onMount(() => {
    done = false;
  });

  function back() {
    router.goto("/todo");
  }
</script>

<div class="container">
    <h1>Thank You</h1>
    <p>Thank you for completing your identity verification.</p>
    <button on:click={back} class="smart-search__complete">Back to To-Do List</button>
</div>

<style>

    .container {
        text-align: center;
    }

    button {
        background-image: linear-gradient(to bottom, #4bbcd0 0%, #348f96 100%);
        border: 1px solid #348f96;
        display: inline-block;
        text-align: center;
        text-decoration: none;
        color: white;
        font-weight: bold;
        padding: 1em 1.5em;
        border-radius: 0.4em;
        cursor: pointer;
    }

</style>
