<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
</script>

<NoSidebar title="Welcome" class="">
    <p>
        Welcome to the Believe Money Customer Portal. Please choose an option:
    </p>
    <div class="options">
        <a href="/register" class="button">
            <h2>This is my first time</h2>
            <p>If you are a new customer and haven't registered for an online account yet, select this option.</p>
        </a>
        <a href="/sign-in" class="button">
            <h2>I've already registered</h2>
            <p>If you have already registered for an online account with us, select this option to sign in.</p>
        </a>
    </div>
</NoSidebar>

<style>
    .options {
        display: flex;
        gap: 1rem;
        align-items: stretch;
        justify-content: space-evenly;
    }

    @media(max-width: 850px) {
        .options {
            flex-direction: column;
        }
    }

</style>
