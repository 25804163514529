<script>
    import { activeCase, caseList, signOut } from "../../store";
    import {compareCaseReference} from "../../utils";

    function groupedCases() {
      const g = {};
      for (const c of $caseList) {
        if (c.portal.brand.name in g) {
          g[c.portal.brand.name].push(c);
        } else {
          g[c.portal.brand.name] = [c];
        }
      }
      return Object.entries(g).map(([ k, v ]) => ({ name: k, cases: v.sort((a, b) => compareCaseReference(a.reference, b.reference)) }));
    }
</script>

<div class="account">
    {#if $caseList && $caseList.length > 1 }
        <div class="case-selector">
            <div>Case:</div>
            <div class="select-style">
                <select bind:value={$activeCase}>
                    {#each groupedCases() as brand}
                        <optgroup label={brand.name}>
                            {#each brand.cases as c}
                            <option value={c}>{c.reference}</option>
                            {/each}
                        </optgroup>
                    {/each}
                </select>
            </div>
        </div>
    {/if}
    <button class="button" on:click={signOut} style="white-space: nowrap">Sign Out</button>
</div>

<style>

    .account {
        display: flex;
        flex-direction: row;
        gap:1em;
        text-align: right;
    }

    .account .button {
        font-size: 0.9em;
    }

    .case-selector {
        display: flex;
        align-items: center;
        justify-content: right;
        gap:0.5em;
    }

    @media screen and (max-width: 767px) {
        .account {
            flex-direction: row;
            align-items: center;
            gap:0.5em;
        }
        .case-selector {
            justify-content: center;
        }
    }

    .select-style {
        padding:0;
        margin:0;
        border: 2px solid #ccc;
        border-radius: 0.3rem;
        overflow: hidden;
    }

    .select-style:hover {
        border: 2px solid #17174c;
    }

    .select-style select {
        text-align: center;
        font-size: 1rem;
        padding: 0.5em 1em;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
    }

    .select-style select:focus {
        outline: none;
    }

</style>