<script>

    export let description = "Loading...";
    export let label = '';
    export let show = true

</script>

<div class="loading-indicator" class:show={show}>
    <svg
            class="loading"
            viewBox="0 0 100 100"
            xmlns="http://www.w3.org/2000/svg"
            height="4em"
            width="4em"
    >
        <circle
                cx="50"
                cy="50"
                r="40"
                stroke="#2691a6"
                stroke-width="10"
                fill="none"
                stroke-dasharray="226.194 226.194"
        />
    </svg>
    <h2>{description}</h2>
    {#if label}
        <h3>{label}</h3>
    {/if}
</div>

<style>

    /* WORKING */
    .loading-indicator {
        margin-top:1rem;
        border-radius: 1rem;
        padding-top:1em;
        background-color: #ffffff;
        z-index: 100;
        display: none;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .loading-indicator.show {
        display:flex;
    }

    @keyframes rotating {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .loading-indicator > svg {
        animation: rotating 1s linear infinite;
    }

</style>