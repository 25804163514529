<script>
  import {activeCaseData} from "../../Shared/store";

  $: incompleteNeeds = $activeCaseData && $activeCaseData.todo ? $activeCaseData.todo.some(a => a.needs.some(n => !n.completed)) : false;
</script>

<div class="incomplete__todos">
    {#if incompleteNeeds}
        <div class="container">
            <a href="/todo" class="button red fill">
                <h3>Incomplete To-Do List</h3>
                <p>There are items on your to-do list, click here to complete them.</p>
            </a>
        </div>
    {/if}
</div>

<style>

    .incomplete__todos {
        margin-bottom:0.5rem;
    }

    .incomplete__todos .button.red.fill {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0.5rem;
        padding:1rem;
        text-align: left;
        border-radius: 1rem;
    }

    .incomplete__todos .button.red.fill > * {
        padding:0;
        margin:0;
        line-height: 1.7rem;
    }

</style>