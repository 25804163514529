export default {
    action(name, callback) {
        recaptcha.enterprise.ready(function () {
            recaptcha.enterprise.execute("googleCaptchaSiteKey", {action: name.toLowerCase()}).then(function (token) {
                callback(token);
            });
        });
    }
}

//
// Initialization
//

let recaptcha = null;
let recaptchaScript = null;

function ready(fn) {
    if (document.readyState !== 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(function () {
   inject();
});

function inject() {
    recaptchaScript = document.createElement("script");
    recaptchaScript.addEventListener("load", initialize);
    recaptchaScript.async = true;
    recaptchaScript.src = `//www.google.com/recaptcha/enterprise.js?render=${"googleCaptchaSiteKey"}`;
    document.body.appendChild(recaptchaScript);
}

function initialize() {
    setTimeout(() => {
        if (window.grecaptcha) {
            recaptcha = window.grecaptcha;
        }
    }, 1);
}