<script>

    import {onMount, setContext} from "svelte";
    import {activePortal} from "../../Shared/store";
    import {portals} from "../../Shared/portals";
    import api from "../../Shared/api";

    // General
    import NoSidebar from "../../Shared/Layouts/NoSidebar.svelte";
    import SimpleStepper from "../../Shared/Layouts/Elements/SimpleStepper.svelte";
    import TrustBox from "../../Shared/Components/TrustBox.svelte";
    import LoadingIndicator from "../../Shared/Components/LoadingIndicator.svelte";

    // Auto AIP States
    import AdditionalInformation from "../../Shared/Components/AIPAuto/States/AdditionalInformation.svelte";
    import Offline from "../../Shared/Components/AIPAuto/States/Offline.svelte";
    import Quoted from "../../Shared/Components/AIPAuto/States/Quoted.svelte";
    import Quotes from "../../Shared/Components/AIPAuto/States/Quotes.svelte";
    import New from "../../Shared/Components/AIPAuto/States/New.svelte";
    import NoQuotes from "../../Shared/Components/AIPAuto/States/NoQuotes.svelte";
    import Proceed from "../../Shared/Components/AIPAuto/States/Proceed.svelte";
    import Completed from "../../Shared/Components/AIPAuto/States/Completed.svelte";
    import Unavailable from "../../Shared/Components/AIPAuto/States/Unavailable.svelte";
    import Reactivate from "../../Shared/Components/AIPAuto/States/Reactivate.svelte";

    // External Vars
    export let accessCode;
    export let portal;

    // Set context for children (use getContext on children to get values)
    setContext('accessCode', accessCode);
    setContext('portal', portal);

    // Set Active Portal
    activePortal.set(portals.all.find(x => x.api === portal));

    // Local Vars
    let quote = null;
    let productQuote = null;
    let selectedQuoteType = null;
    let selectedQuoteFilters = [];
    let selectedQuoteSorting = {
        by: 'rate',
        descending: false
    };
    let steps = [];
    let currentStepIndex = 0;

    // Mounted
    onMount(async () => {
        await initQuote();
    });

    // init quote
    async function initQuote() {
        // Get Quote
        await getQuote();
        // Check if processing (reset label index)
        checkIfProcessing(true);
    }

    // Monitor for changes - only runs if quote is changed.
    $: {
        if (quote) {
            setupQuote();
        }
    }

    // Update stepper from quote result
    function setupQuote() {
        steps = ['Your Information', 'Quotes', 'Income & Expenditure'];
        currentStepIndex = 0;
        if (quote) {
            if (quote.success) {
                if (quote.status === "processing") {
                    currentStepIndex = 1;
                    return;
                } else if (quote.status === "additionalInformationRequired") {
                    return;
                } else if (quote.status === "unavailable") {
                    currentStepIndex = 1;
                    steps = ['Call Required'];
                    return;
                } else if (quote.status === "quoted") {
                    if (quote.quotes.length > 0) {
                        currentStepIndex = 1;
                        if (productQuote != null) {
                            if (selectedQuoteType === "productTransfer") {
                                steps = ['Give your Consent', 'Select Quote', 'Product Transfer', 'Sign', 'Income & Expenditure'];
                            }
                            currentStepIndex = 2;
                        }
                        return;
                    } else {
                        steps = ['Your Information', 'Request Callback'];
                        currentStepIndex =  steps.length - 1;
                        return;
                    }
                } else if (quote.status === "complete") {
                    currentStepIndex = steps.length;
                    return;
                }
            }
        }
        steps = [];
        currentStepIndex = 0;
    }

    // Update loan amount
    async function updateQuote(e) {
        let {loanAmount, loanTerm, postUpdate, filters, sort} = e.detail;
        if (filters) {
            selectedQuoteFilters = filters;
        }
        if (sort) {
            selectedQuoteSorting = sort;
        }
        //console.log(selectedQuoteFilters, selectedQuoteSorting);
        if (postUpdate) {
            quote = await api.autoAipUpdateQuote(portal, accessCode, { loanAmount, loanTerm });
            // wait for processing to kick in
            await new Promise(resolve => setTimeout(resolve, 2000));
            await initQuote()
        }
    }

    function debugQuote(result) {

        if (!isProduction) {
            //console.log('Quote Debug');
            // MARK; - Use this to change the current quote state
            //result.status = "quotedp";
            //result.retarget=true;
            //result.creditSearchConsentRequired = true;
            //result.quotes = [];
            // result.requiredFields = [
            //     {name: "propertyValue"},
            //     {name: "chargeBalance"},
            //     {name: "employmentStatus"},
            //     {name: "raiseAmount"}
            // ];
        }


        return result;
    }

    // Fetch Quote from API - try for up to 30s as there may be a delay while the quote is created on the backend
    async function getQuote() {
      for (let i = 0; i < 6; i++) {
        let result = await api.autoAipStatus(portal, accessCode);
        if (result.success || i === 5) {
            result = debugQuote(result);
            quote = result;
            // Track page view
            if (result.success) {
                await api.autoAipPageView(portal, accessCode);
            }
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 5000));
      }
    }

    // Processing polling and message updating
    let poll;
    function checkIfProcessing(reset) {
        // Reset message index
        if (reset) processingIndex = 0;
        // Reset timeout
        clearInterval(poll);
        // If still processing
        if (quote.success && quote.status === "processing") {
            // Repeat after x seconds
            poll = setTimeout(async () => {
                // Change label text
                processingIndex = processingIndex >= processing.length - 1 ? 0 : processingIndex + 1;
                // Get Quote
                await getQuote();
                // Repeat check
                checkIfProcessing(false);
            }, 5000);
        }
    }
    let processingIndex = 0;
    let processing = [
        'This may take up to 30 seconds, please wait...',
        'Working on your quote, please wait...',
        'We are still here, not long now...',
        'Not long now, thank you for waiting...',
        'Gathering final details...',
        'Your quote will be here soon, please wait...'
    ]

</script>

<NoSidebar class="no-sidebar__quote__container" transparent nomargin>

    <div class="min-content-width">

        <SimpleStepper
                stepIndex={currentStepIndex}
                steps={steps}
        />

    {#if !quote}
        <LoadingIndicator description="Loading your quote, please wait..." />
    {:else}
        <div class="auto-aip">
            {#if quote.success}
                {#if quote.status === "processing"}
                    <LoadingIndicator
                            description="Processing quote..."
                            label={processing[processingIndex]}
                    />
                {:else if quote.status === "unavailable"}
                    <Unavailable />
                {:else if quote.status === "offline"}
                    <Offline
                            bind:quote={quote}
                            on:online={() => checkIfProcessing(true)}
                    />
                {:else if quote.status === "callRequired"}
                    <NoQuotes
                            bind:quote={quote}
                            on:updateQuote={updateQuote}
                    />
                {:else if quote.status === "additionalInformationRequired"}
                    <AdditionalInformation
                            bind:quote={quote}
                            on:submit={() => checkIfProcessing(true)}
                    />
                {:else if quote.status === "quoted"}
                    {#if quote.quotes.length > 0}
                        {#if productQuote != null}
                            <Proceed
                                    bind:quote={quote}
                                    bind:productQuote={productQuote}
                                    bind:currentStepIndex={currentStepIndex}
                                    bind:selectedQuoteType={selectedQuoteType}
                            />
                        {:else}
                            {#if selectedQuoteType == null}
                                <Quoted
                                        bind:quote={quote}
                                        bind:productQuote={productQuote}
                                        bind:selectedQuoteType={selectedQuoteType}
                                        bind:filters={selectedQuoteFilters}
                                        bind:sort={selectedQuoteSorting}
                                        on:updateQuote={updateQuote}
                                />
                            {:else}
                                <Quotes
                                        bind:quote={quote}
                                        bind:productQuote={productQuote}
                                        bind:selectedQuoteType={selectedQuoteType}
                                        bind:filters={selectedQuoteFilters}
                                        bind:sort={selectedQuoteSorting}
                                        on:updateQuote={updateQuote}
                                />
                            {/if}
                        {/if}
                    {:else}
                        <NoQuotes
                                bind:quote={quote}
                                on:updateQuote={updateQuote}
                        />
                    {/if}
                {:else if quote.status === "complete"}
                    <Completed
                            bind:quote={quote}
                            on:callback={() => checkIfProcessing(true)}
                    />
                {:else if quote.status === "reactivate"}
                    <Reactivate
                            bind:quote={quote}
                            on:online={() => checkIfProcessing(true)}
                    />
                {:else}
                    <New
                            bind:quote={quote}
                            on:online={() => checkIfProcessing(true)}
                    />
                {/if}
            {:else}
                <p>{quote.error}</p>
            {/if}
        </div>
    {/if}

    </div>

    <div slot="footer">
        {#if quote && quote.status !== "processing"}
            <footer>
                <div class="container">
                    <TrustBox cssClass="padding-top"
                              templateId={ $activePortal.trustPilot.templateId }
                              businessUnitId={ $activePortal.trustPilot.businessUnitId }
                              domain={ $activePortal.brand.domain }
                    />
                </div>
                <p>
                    This Indicative Quote does not constitute a mortgage offer and does not entitle its recipients to a mortgage advance from the lender. Exact figures and interest rates will be fully explained in your Mortgage Illustration, please ensure you fully read through this once you receive it. Any offer of a mortgage is subject to the prevailing terms and conditions and prior to the lenders full underwriting assessment.
                </p>
                {#if portal === "second-charge"}
                <p>
                    <strong>Representative Example</strong><br />
                    If you borrow £30,000 over 180 months at a rate of 7.84% fixed for 5 years, you will pay 60 instalments of £309.72 per month followed by 120 months at a rate of 8.59% of £319.96 and a total amount payable of £57,153.40. This includes a broker fee of £1,995, a lender fee of £695 and the net loan interest of £24,253.85. The overall cost for comparison is 8.70% APRC Variable.
                </p>
                {/if}
                {#if portal !== "second-charge"}
                    <p>
                    <strong>Representative Example</strong><br/>
                        A mortgage of £135,000.00 payable over 20 years, initially on a fixed rate for 5 years at 4.59% and then on a variable rate of 6.29% for the remaining term would require 63 payments of £866.04 followed by payments of £966.15. The total amount payable would be £226,564.07 made up of the loan amount plus interest (£90,077.17) and fees (£995). The overall cost for comparison is 6.0% APRC representative.
                    </p>
                    <p>
                        A mortgage of £135,000.00 payable over 20 years, initially on a tracker rate for 2 years at 3.24% and then on a variable rate of 6.49% for the remaining term would require 24 payments of £770.69 followed by payments of £990.41. The total amount payable would be £233,500.12 made up of the loan amount plus interest (£96,426.38) and fees (£995). The overall cost for comparison is 6.1% APRC representative.
                    </p>
                {/if}
                <p>
                    <strong
                    >Your home may be repossessed if you do not keep up repayments on your mortgage.</strong
                    >
                </p>
            </footer>
        {/if}
    </div>
</NoSidebar>


<style>

    .auto-aip {
        text-align: center;
        padding:0 1rem;
    }

    footer {
        background-color: #ffffff;
        padding: 1em;
        border-top: 1px solid #cccccc;
    }

    .min-content-width {
        margin: 0 auto;
        max-width: 850px;
    }

    :global(.no-sidebar__quote__container > div) {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    :global(.no-sidebar__quote__container > div > div) {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        max-width: 850px;
    }

</style>
