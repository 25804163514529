<script>
    import { DateTime } from "luxon";

    export let callback = {
        date: '',
        time: ''
    }

    const minCallbackDate = new Date();

    const callbackTimes = (date) => {
        const start = 9;
        const end = date.weekday === 5 ? 13 : 18;
        let times = [];
        for (let time = start; time <= end; time++) {
            times.push({value: { hour: time, minute: 0, seconds: 0 }, label: `${time}:00`})
            times.push({value: { hour: time, minute: 15, seconds: 0 }, label: `${time}:15`})
            times.push({value: { hour: time, minute: 30, seconds: 0 }, label: `${time}:30`})
            if(date.weekday < 5 && time !== end) {
                times.push({value: {hour: time, minute: 45, seconds: 0}, label: `${time}:45`})
            }
        }
        if(date.weekday < 5) {
            times = times.slice(2)
        }
        return times;
    }

    const callbackDays = () => {
        const now = DateTime.fromJSDate(minCallbackDate);
        const days = [];
        for (let day = 0; days.length < 7; day++) {
            const d = now.plus({days:day});
            if(d.weekday < 6) {
                const suffix = d.day === 1 || d.day === 21 || d.day === 31 ? "st" : d.day === 2 || d.day === 22 ? "nd" : d.day === 3 || d.day === 23 ? "rd" : "th";
                days.push({
                    value: d,
                    label: `${d.weekdayLong} ${d.day}${suffix} ${d.monthLong}`,
                    dayOfWeek: d.weekday
                })
            }
        }
        return days;
    }

</script>

<div class="callback">
    <div class="select-style">
        <select bind:value={callback.date}>
            <option value="" disabled selected>Day</option>
            {#each callbackDays() as {value, label}, i}
                <option value={value}>{label}</option>
            {/each}
        </select>
    </div>
    <div class="select-style">
        <select bind:value={callback.time} disabled={!callback.date}>
            <option value="" disabled selected>Time</option>
            {#each callbackTimes(callback.date) as {label, value}, i}
                <option value={value}>{label}</option>
            {/each}
        </select>
    </div>
</div>

<style>

    .callback {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.5em;
        align-items: stretch;
        justify-content: space-evenly;
    }

    .select-style {
        width:100%;
        flex-grow: 1;
        padding:0;
        margin:0;
        border: 2px solid #ccc;
        border-radius: 5px;
        overflow: hidden;
    }

    .select-style:hover {
        border: 2px solid #348f96;
    }

    .select-style select {
        width:100%;
        text-align: center;
        font-size: 1rem;
        padding: 8px;
        border: none;
        box-shadow: none;
        -webkit-appearance: none;
    }

    .select-style select:focus {
        outline: none;
    }

</style>
