
export function formatLoanTerm(term) {
  const years = Math.floor(term / 12);
  const months = term % 12;
  const yearsText = years === 1 ? "1 year" : `${years} years`;
  const monthsText = months === 0 ? "" : months === 1 ? " 1 month" : ` ${months} months`;
  return yearsText + monthsText;
}

export function getFormattedDateForUpload() {
  const dt = new Date(Date.now());
  let cd = [
    dt.getFullYear(),
    dt.getMonth() + 1 < 10 ? '0' : '',
    dt.getMonth() + 1,
    dt.getDate() < 10 ? '0' : '',
    dt.getDate(),
    '-',
    dt.getHours() < 10 ? '0' : '',
    dt.getHours(),
    dt.getMinutes() < 10 ? '0' : '',
    dt.getMinutes(),
  ]
    return cd.map(x => x.toString()).filter(x => x.length > 0).join('');
}

export function formatDocumentDate(d) {
  const dt = new Date(d);
  return `${dt.getDate()}/${dt.getMonth() + 1}/${dt.getFullYear()} @ ${dt.getHours() % 12}:${dt.getMinutes() < 10 ? "0" : ""}${dt.getMinutes()}${dt.getHours() < 12 ? "am" : "pm"}`;
}

export function aipAccessCode() {
  if(!window.location.pathname.toUpperCase().startsWith("/AIP")) return "";
  return window.location.pathname.split("/")[3];
}

export function quoteAccessCode() {
  if(!window.location.pathname.toUpperCase().startsWith("/QUOTE")) return "";
  return window.location.pathname.split("/")[3];
}

export function compareCaseReference(a, b) {
  // take into account ref number system changes
  // BL 5 to 4
  // BF 7 to 2
  // BSF 3 to 1
  const prefixA = Math.floor(a / 1000000);
  const prefixB = Math.floor(b / 1000000);

  const systemA = prefixA === 5 || prefixA === 4 ? 1 : prefixA === 7 || prefixA === 2 ? 2 : prefixA === 3 || prefixA === 1 ? 3 : 4;
  const systemB = prefixB === 5 || prefixB === 4 ? 1 : prefixB === 7 || prefixB === 2 ? 2 : prefixB === 3 || prefixB === 1 ? 3 : 4;

  if (systemA < systemB) {
    return -1;
  }
  if (systemA > systemB) {
    return 1;
  }

  if ((prefixA === 5 && prefixB === 4) || (prefixA === 7 && prefixB === 2) || (prefixA === 3 && prefixB === 1)) {
    return 1;
  }
  if ((prefixA === 4 && prefixB === 5) || (prefixA === 2 && prefixB === 7) || (prefixA === 1 && prefixB === 3)) {
    return -1;
  }

  return b - a;
}

export function arrayRange(start, stop, step) {
  return Array.from(
      {length: (stop - start) / step + 1},
      (value, index) => start + index * step
  );
}