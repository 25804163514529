<script>
  import SidebarNavigation from "../Shared/Layouts/SidebarNavigation.svelte";
  import {activeCase, activeCaseData} from "../Shared/store";
  import {loanPurposes} from "../Shared/lists";
  import IncompleteNeeds from "./Home/IncompleteNeeds.svelte";
  import ProtectionAlert from "./Home/ProtectionAlert.svelte";
  import Footer from "./Home/Footer.svelte";
  import Quoted from "./Home/Quoted.svelte";
  import {onMount} from "svelte";
  import Needs from "../Shared/Components/Needs/Needs.svelte";

  $: isProtect = $activeCase.portal.api === 'protect';

  $: quoted = $activeCase && $activeCase.product && !isProtect;
  $: title = quoted ? `${$activeCase.portal.term} Approved in Principle` : "We're working on your quote";
  $: subTitle = quoted ? `Great news, you have been accepted for a ${$activeCase.portal.term.toLowerCase()} with ${lender}!` : `Please check back later for updates. In the mean time, if you need us you can always call us on ${$activeCase.portal.brand.telephone}.`;
  $: lender = quoted && $activeCase.product.lender ? $activeCase.product.lender : $activeCase.portal.brand.name;
  $: loanTerm = quoted && $activeCase.product.rates ? $activeCase.product.rates.reduce((a, b) => a + b.term, 0) : null;
  $: initialMonthlyPayment = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].monthlyRepayment : null;
  $: initialRate = quoted && $activeCase.product.rates && $activeCase.product.rates.length ? $activeCase.product.rates[0].rate : null;
  $: loanPurpose = $activeCase.loanPurposes.length ? loanPurposes[$activeCase.loanPurposes[0]] ? loanPurposes[$activeCase.loanPurposes[0]] : "Other" : "Other";

  $: incompleteNeeds = $activeCaseData && $activeCaseData.todo ? $activeCaseData.todo.some(a => a.needs.some(n => !n.completed)) : false;
  $: protection = $activeCaseData && $activeCaseData.protection ? $activeCaseData.protection : [];
  $: protectionStartingFrom = protection.length ? protection.sort((a, b) => a.premium - b.premium)[0].premium : null;

</script>

<SidebarNavigation title={ isProtect ? 'My To-Do List' : ''}>
    {#if $activeCase}
        <div class={`welcome${isProtect ? ' welcome-protect' : ''}`}>
            <IncompleteNeeds/>
            <ProtectionAlert/>
            {#if isProtect}
                <Needs/>
            {:else}
                <Quoted/>
            {/if}
        </div>
    {/if}
    {#if quoted}
        <Footer slot="footer"/>
    {/if}
</SidebarNavigation>

<style>

    .welcome {
        text-align: center;
        padding: 1.5rem 1rem 1rem 1rem;
    }

    .welcome-protect {
        text-align: left;
    }

    .terms .container div {
        background-color: #bfe5ee;
        border-radius: 0.5rem;
    }

    .terms .container {
        display: flex;
        padding: 0;
        gap: 0.5rem;
    }

    .terms .container > div {
        display:flex;
        flex: 1;
        padding: 0.5em;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

    }
    .terms .container > div > span {
        font-weight: bold;
        color: hsl(190, 64%, 30%);
        display: block;
        margin: 1em 0;
    }

    .terms .container > div > p {
        color: #124853;
        font-size: 1.75em;
    }

    .call {
        margin-top: 1rem;
    }


    @media screen and (max-width: 650px) {
        .terms .container {
            flex-direction: column;
        }
        .terms .container > div > span,
        .terms .container > div > p {
            display: block;
            margin:0;
            padding:0.5rem;
        }
    }

</style>