<script>
  import {createEventDispatcher} from 'svelte';
  const dispatch = createEventDispatcher();

  import Document from "../Document.svelte";
  import Uploader from "../Uploader.svelte";
  import SmartSearch from "../SmartSearch/SmartSearch.svelte";

  export let needs = [];
  export let name = "";

</script>

<div class="documents">
    {#each needs as need, index (`${need.id}_${index}`)}
        <Document>
            <span slot="icon">
                {#if !need.completed}
                    {#if need.type == "esign"}
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#dd1d42" width="2em"
                             style="margin-right:1em">
                            <path d="M0 18h20v1H0zm-.003-6.155l1.06-1.06 4.363 4.362-1.06 1.06z"/>
                            <path d="M.004 15.147l4.363-4.363 1.06 1.061-4.362 4.363zM17 5c0 9-11 9-11 9v-1.5s8 .5 9.5-6.5C16 4 15 2.5 14 2.5S11 4 10.75 10c-.08 2 .75 4.5 3.25 4.5 1.5 0 2-1 3.5-1a2.07 2.07 0 0 1 2.25 2.5h-1.5s.13-1-.5-1C16 15 16 16 14 16c0 0-4.75 0-4.75-6S12 1 14 1c.5 0 3 0 3 4z"/>
                        </svg>
                    {:else}
                        <div class="circle red">&#8679;</div>
                    {/if}
                {:else}
                    <div class="circle green">&#10004;</div>
                {/if}
            </span>
            <h3>{need.description}</h3>
            <p>{need.notes ? need.notes : ""}</p>
            {#if !need.completed}
                {#if need.type == "need" }
                    <Uploader aip={false}
                              basic={true}
                              uploadType={need.description}
                              on:uploaded={(e) => dispatch('uploaded', {need, e})}
                              button="Add"
                              small={true}
                    />
                {/if}
                {#if need.type == "smartsearch" }
                    {#if need.authorised}
                        <SmartSearch detail={need}/>
                    {:else}
                        <p>
                            <small>{name.forename} must complete the check from their portal account</small>
                        </p>
                    {/if}
                {/if}
                {#if need.type == "esign"}
                    {#if need.authorised}
                        <p>
                            <button class="button green fill pill small" on:click={() => dispatch('esign', {need})}>Proceed to Sign</button>
                        </p>
                    {:else}
                        <p>
                            <small>{name.forename} must sign this document from their portal account</small>
                        </p>
                    {/if}
                {/if}
            {/if}
        </Document>
    {/each}
</div>

<style>

    .documents-needed .documents .document {
        cursor: pointer;
    }

    .documents {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 1em;
        margin-bottom: 1em;
    }

    .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
        border-radius: 50%;
        font-size: 1.5rem;
        font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        font-weight: bold;
        aspect-ratio: 1;
        color:white;
    }

    .circle.red {
        border: 2px solid #dd1d42;
        background: #dd1d42;
    }

    .circle.green {
        border: 2px solid #01b57a;
        background: #01b57a;
    }

    @media screen and (max-width: 1236px) {
        .documents {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (max-width: 767px) {
        .documents {
            grid-template-columns: 1fr;
        }
    }

</style>
