<script>
    import {createEventDispatcher} from 'svelte';
    import {formatDocumentDate} from "../utils";
    import {user} from "../store";
    import {activeCase} from "../store";

    const dispatch = createEventDispatcher();

    export let doc = null;
    export let closable = false;
    export let icon = true;

    function remove() {
        dispatch("remove");
    }
</script>

<div class="document">
    <slot name="icon">
        {#if icon}
            <svg xmlns="http://www.w3.org/2000/svg" width="2em" viewBox="0 0 102.256 124.588">
                <path id="Path_39" data-name="Path 39" d="M2653.37,988.412a15.249,15.249,0,0,0-15.28,15.279v94.029a15.249,15.249,0,0,0,15.28,15.28h71.7a15.249,15.249,0,0,0,15.279-15.28v-94.029a15.249,15.249,0,0,0-15.279-15.279Zm0,7.052h71.7a8.177,8.177,0,0,1,8.227,8.227v94.029a8.177,8.177,0,0,1-8.227,8.228h-71.7a8.177,8.177,0,0,1-8.228-8.228v-94.029A8.177,8.177,0,0,1,2653.37,995.464Zm8.227,18.806a3.526,3.526,0,0,0,0,7.052h52.891a3.526,3.526,0,0,0,0-7.052Zm0,17.63a3.526,3.526,0,0,0,0,7.052h52.891a3.526,3.526,0,0,0,0-7.052Zm0,17.631a3.526,3.526,0,0,0,0,7.052h24.095a3.526,3.526,0,1,0,0-7.052Zm35.872,23.055a3.814,3.814,0,0,0-2.962,1.51l-6.581,9.991-4.114-5.407a3.527,3.527,0,1,0-5.643,4.231l6.935,9.286a3.568,3.568,0,0,0,2.939,1.41h.117a3.8,3.8,0,0,0,2.938-1.646l9.286-13.988a3.441,3.441,0,0,0-2.915-5.387Z" transform="translate(-2638.09 -988.412)"/>
            </svg>

        {/if}
    </slot>
    <div>
        <slot>
            <h3>{ doc.displayName }</h3>
            <p>Uploaded { formatDocumentDate(doc.createdAt) }</p>
            <p>
                <a class="button green fill pill small" href={`/api/${$activeCase.portal.api}/${$user.accountId}/case/${$activeCase.id}/documents/${doc.id}`} download={doc.fileName}
                   tinro-ignore>Download</a>
            </p>
        </slot>
    </div>
    {#if closable}
        <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                class="remove"
                on:click={remove}
        >
            <line
                    x1="5"
                    y1="5"
                    x2="95"
                    y2="95"
                    stroke="#cccccc"
                    stroke-width="20"
            />
            <line
                    x1="95"
                    y1="5"
                    x2="5"
                    y2="95"
                    stroke="#cccccc"
                    stroke-width="20"
            />
        </svg>
    {/if}
</div>

<style>
    .document {
        display: flex;
        background-color: rgba(0, 0, 0, 0.05);
        padding: 1em;
        /*border: 1px solid #cccccc;*/
        border-radius: 0.5rem;
        text-decoration: none;
        color: #2c3e50;
        align-items: center;
    }

    .document > svg:first-child,
    .document span svg {
        margin-right: 1em;
    }

    .document > div {
        flex: 1;
    }

    .document > div > :global(h3) {
        margin: 0;
    }

    .document > div > :global(p) {
        font-size: 0.8em;
        color: #666666;
        margin: 0.5em 0;
    }

    .document > div > :global(*:last-child) {
        margin-bottom: 0;
    }

    .remove {
        cursor: pointer;
        margin-left: 1em;
    }

    @media(max-width: 850px) {
        .document > div > :global(h3) {
            font-size: 0.9rem;
        }
    }

</style>
