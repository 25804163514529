<script>
  import {user} from "../Shared/store";
  import {activeCase, caseList} from "../Shared/store";
  import Unauthenticated from "./Account/Unauthenticated.svelte";
  import Verify from "./Account/Verify.svelte";
</script>
{#if $user.signedIn}
    {#if $user.requiresVerification}
        <Verify />
    {:else if $activeCase}
        <slot></slot>
    {:else}
        <div class="container">
            {#if $caseList === null}
                <p>Loading...</p>
            {:else if $caseList.length === 0}
                <h1>No applications found</h1>
                <p>We couldn't find any applications linked to your account.</p>
                <p>If you weren't expecting this, please contact us for further assistance.</p>
            {/if}
        </div>
    {/if}
{:else}
    <Unauthenticated/>
{/if}
