<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
  import {onMount} from "svelte";
  import api from "../../Shared/api";
  import LoadingIndicator from "../../Shared/Components/LoadingIndicator.svelte";
  import {completeVerification} from "../../Shared/store";

  const channelText = {
    email: "Send an e-mail to",
    sms: "Send a text message to",
    call: "Receive an automated phone call on"
  };

  let loading = true;
  let options = [];
  let selectedMethod = null;
  let pending = JSON.parse(sessionStorage.getItem("verify_pending"));
  let code = null;
  let codeError = null;

  onMount(async () => {
    options = await api.getVerificationOptions();
    loading = false;
  });

  async function sendCode() {
    loading = true;
    await api.requestCode(selectedMethod.channel, selectedMethod.tag);
    sessionStorage.setItem("verify_pending", JSON.stringify(selectedMethod));
    pending = selectedMethod;
    loading = false;
  }

  function reset() {
    selectedMethod = null;
    sessionStorage.removeItem("verify_pending");
    pending = null;
    code = null;
    codeError = null;
  }

  $: codeValid = code && code.length && code.length === 6

  async function checkCode() {
    loading = true;
    const result = await api.checkCode(code, pending.tag);
    if (result && result.success && result.token) {
      sessionStorage.removeItem("verify_pending");
      completeVerification(result.token);
      return;
    }
    codeError = "The code entered was not correct. Please try again."
    loading = false;
  }

  function handleEnter(e) {
    if (!loading && e.key === "Enter") {
      checkCode();
    }
  }
</script>

<NoSidebar title="Account Verification">
    <div class="verify">
        {#if loading}
            <LoadingIndicator/>
        {:else if pending}
            {#if pending.channel === "sms"}
                <p>We've sent an SMS containing a 6 digit code to <strong>{pending.recipient}</strong>. Please enter the
                    code you receive below to finish account verification.</p>
            {:else if pending.channel === "email"}
                <p>We've sent an e-mail containing a 6 digit code to <strong>{pending.recipient}</strong>. Please enter the
                    code you receive below to finish account verification.</p>
            {:else if pending.channel === "call"}
                <p>You will shortly receive an automated phone call on <strong>{pending.recipient}</strong> where you will
                    be given a 6 digit code. Please enter the code you receive below to finish account verification.</p>
            {/if}
            <input type="text" class="code-entry" placeholder="Enter code" maxlength="6" bind:value={code} on:keyup={handleEnter} />
            {#if codeError}
                <p class="code-error">
                    {codeError}
                </p>
            {/if}
            <p>
                <button class="button" disabled={!codeValid} on:click={() => checkCode()}>Submit</button>
            </p>
            <p><strong>Not received a code?</strong><br/>
                It can take up to a couple of minutes to come through - if you have not received a code by this time, you
                can <a href="" on:click|preventDefault={() => reset()}>try again</a>.</p>
        {:else}
            <p>
                To complete the registration process, we need to verify your account. We do this by sending you a 6 digit
                code, either via e-mail, SMS or a phone call.
            </p>
            <p><strong>Verification Method</strong></p>
            <p>Please select how you would like to receive your verification code:</p>
            {#each options as option}
                <label class="option">
                    <input type="radio" bind:group={selectedMethod} name="verificationMethod" value={option}/>
                    <span>{channelText[option.channel]} <strong>{option.recipient}</strong></span>
                </label>
            {/each}
            <p>
                <button class="button" disabled={!selectedMethod} on:click={() => sendCode()}>Send Code</button>
            </p>
        {/if}
    </div>
</NoSidebar>

<style>
    .verify {
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    .verify input {
        width:100%;
    }

    .option {
        display: flex;
        align-items: center;
    }

    .option input {
        width: 32px;
    }

    .option span {
        padding: 0.5em;
        flex: 1;
        text-align: left;
    }

    .code-entry {
        display: block;
        margin: 0em;
        padding: 0.5em;
        font-size: 2em;
        width: 5em;
        text-align: center;
    }

    .code-error {
        color: red;
    }
</style>
