<script>

    import {getContext, createEventDispatcher} from 'svelte';
    import {activePortal} from "../../../store";

    const dispatch = createEventDispatcher();

    import Callback from "../../Callback.svelte";
    import api from "../../../api";

    /* From Context */
    const accessCode = getContext('accessCode');
    const portal = getContext('portal');

    /* Passed Vars */
    export let quote;

    /* Local Vars */
    let working = false;
    let callback = {
        date: '',
        time: ''
    }
    let searchConsent = false;

    /* Functions */
    async function online() {
        working = true;
        quote = await api.autoAipUpdate(portal, accessCode, "online");
        working = false;
        dispatch("online");
    }

    const offline = async () => {
        working = true;
        quote = await api.autoAipUpdate(portal, accessCode, "offline", {
            callbackTime: callback.date.set(callback.time).toUTC().toISO()
        });
        working = false;
    }

</script>

<div class="container auto-aip__new">
    {#if quote.retarget}
        <h2>
            Welcome{quote.name ? ` ${quote.name}` : ""}, we have your details from your online application, so your quote is only seconds away!
        </h2>
        <div class="auto-aip__new__info__box auto-aip__new__features">
            <div><span>&#10004;</span><div>Loans for any purpose with rates from <strong>2.9%</strong></div></div>
            <div><span>&#10004;</span><div>Loans from &pound;5,000 up to &pound;500,000</div></div>
            <div><span>&#10004;</span><div>Access to over 900 products</div></div>
            <div><span>&#10004;</span><div>All circumstances considered</div></div>
            <div><span>&#10004;</span><div><strong>Online and digital journeys for fast completion</strong></div></div>
        </div>
        <p>To proceed online we need your consent to run a soft quotation credit search. This won't affect your credit rating - you can find more information in our <a
                    href="https://{ $activePortal.brand.domain }/terms"
                    target="_blank">terms
                &amp; conditions</a> and <a href="https://{ $activePortal.brand.domain }/privacy" target="_blank">privacy policy</a>.</p>
        <p>
            <a href="#" on:click|preventDefault="{() => { searchConsent = !searchConsent}}" class={`consent ${searchConsent ? 'green' : ''}`}>
                <span class="check">&#10004;</span><span>I accept</span>
            </a>
        </p>
        <p>
            <button class="button green fill pill"
                    on:click={() => online()}
                    disabled={working || !searchConsent} >Get My Quote
            </button>
        </p>
        <p>Apply above for an indicative quote <strong>without</strong> harming your credit history</p>
    {:else}
        {#if quote.name}
            <h1>Welcome to { $activePortal.brand.name }, {quote.name}!</h1>
        {:else}
            <h1>Welcome to { $activePortal.brand.name }!</h1>
        {/if}
        <h2>{ $activePortal.brand.name } is one of the UK's market-leading { $activePortal.term.toLowerCase() } brokers.</h2>
        <h3>We've received your information, so let's get started on your quote. How would you like to proceed?</h3>
        <div class="auto-aip__new__options">
            <div class="auto-aip__new__info__box auto-aip__new__options__online">
                <h2>Online</h2>
                <p>In many cases you don't have to speak to us to get your quote, tap <strong>Continue</strong> below and we  will try and get you a quote online now!</p>
                {#if quote.creditSearchConsentRequired}
                    <p>To proceed online we need your consent to run a soft quotation credit search. This won't affect your credit rating - you can find more information in our <a
                                href="https://{ $activePortal.brand.domain }/terms"
                                target="_blank">terms &amp; conditions</a> and <a href="https://{ $activePortal.brand.domain }/privacy"
                                                        target="_blank">privacy policy</a>.</p>
                    <p>
                        <a href="#" on:click|preventDefault="{() => { searchConsent = !searchConsent}}" class={`consent ${searchConsent ? 'green' : ''}`}>
                            <span class="check">&#10004;</span><span>I accept</span>
                        </a>
                    </p>
                {/if}
                <button class="button fill green pill"
                        on:click={() => online()}
                        disabled={working || (quote.creditSearchConsentRequired && !searchConsent)}>Continue
                </button>
            </div>
            <div class="auto-aip__new__info__box auto-aip__new__options__offline">
                <h2>Over the phone</h2>
                <p>If you prefer, select a good time to call and one of our award winning team will be in touch to go through your quote.</p>
                <form on:submit|preventDefault={offline}>
                    <Callback bind:callback={callback}/>
                    <button
                            class="button green fill mt pill"
                            type="submit"
                            disabled={!callback.date || !callback.time || working}
                    >Request Call
                    </button>
                </form>
            </div>
        </div>
    {/if}
</div>

<style>


    .auto-aip__new {
        width: 100%;
        margin-top: 1rem;
        padding: 1rem;
        background-color: white;
        border: 3px solid #01b57a;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
        text-align: center;
    }


    .auto-aip__new__info__box {
        display: inline-flex;
        padding: 1rem;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
        background-color: white;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 0.75rem;
    }

    .auto-aip__new__options {
        display: flex;
        gap:1rem;
        padding:1rem;
    }

    .auto-aip__new__options h2 {
        margin:0;
        padding:0;
    }

    .auto-aip__new__options form {
        display: flex;
        flex-direction: column;
    }

    .auto-aip__new__features {
        display: inline-flex;
        align-items: start;
    }

    @media(max-width: 850px) {
        .auto-aip__new__options {
            flex-direction: column;
        }
    }

    /* -------------------------------------------------- */
    /* Retarget */
    /* -------------------------------------------------- */

    .consent {
        font-size:1.25rem;
        display: inline-flex;
        flex-direction: row;
        gap:0.5rem;
        align-items: center;
        padding: 0.5rem 1rem;
        text-decoration: none;
    }

    .consent .check {
        display:flex;
        width:2rem;
        height:2rem;
        border: 2px solid #bfe5ee;
        border-radius: 50%;
        background-color: white;
        align-items: center;
        justify-content: center;
        color:white;
    }

    .consent.green .check {
        color: #00a854;
        border-color: #00a854;
    }

    .auto-aip__new__features {
        gap:1rem;
    }

    .auto-aip__new__features > div {
        display: flex;
        align-items: center;
        text-align: left;
    }

    .auto-aip__new__features span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        border: 2px solid #01b57a;
        font-size: 1.5rem;
        font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        margin-right:1rem;
        font-weight: bold;
        aspect-ratio: 1;
        background: #01b57a;
        color:white;
    }

</style>
