<div class="container">
    <p>
        <svg
                viewBox="0 0 100 100"
                xmlns="http://www.w3.org/2000/svg"
                height="100"
                width="100"
        >
            <circle
                    cx="50"
                    cy="50"
                    r="48"
                    stroke="#cccccc"
                    stroke-width="2"
                    fill="white"
            />
            <circle cx="50" cy="50" r="42" fill="red"/>
            <line
                    x1="35"
                    y1="35"
                    x2="65"
                    y2="65"
                    stroke="white"
                    stroke-width="5"
            />
            <line
                    x1="65"
                    y1="35"
                    x2="35"
                    y2="65"
                    stroke="white"
                    stroke-width="5"
            />
        </svg>
    </p>
    <h1>Page Not Found</h1>
    <p>Sorry, we couldn't find the page you were looking for.</p>
    <p>
        Please visit our <a href="/">home page</a>.
    </p>
</div>

<style>
    .container {
        text-align: center;
    }
</style>