<script>

  import {getContext, createEventDispatcher} from 'svelte';
  import {activePortal} from "../../../store";

  const dispatch = createEventDispatcher();

  import Callback from "../../Callback.svelte";
  import api from "../../../api";

  /* From Context */
  const accessCode = getContext('accessCode');
  const portal = getContext('portal');

  /* Passed Vars */
  export let quote;

  /* Local Vars */
  let working = false;
  let callback = {
    date: '',
    time: ''
  }

  /* Functions */
  const offline = async () => {
    working = true;
    quote = await api.autoAipUpdate(portal, accessCode, "offline", {
      callbackTime: callback.date.set(callback.time).toUTC().toISO()
    });
    working = false;
  }

</script>

<div class="auto-aip__noquotes">
    <div class="auto-aip__noquotes__info">
        <div class="auto-aip__noquotes__info__box auto-aip__noquotes__info__callback">
            <h4>To continue with your quote, we need to speak to you.</h4>
            <p>Select a good time to call and one of our award winning team will be in touch to go through your quote.</p>

            <div class="auto-aip__noquotes__info__content">
                <h2>Over the phone</h2>
                <p>Book a call back.</p>
                <form on:submit|preventDefault={offline}>
                    <Callback bind:callback={callback}/>
                    <button
                            type="submit"
                            class="button green fill mt pill"
                            disabled={!callback.date || !callback.time || working}
                    >Request Call
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>

<style>

    .auto-aip__noquotes {
        margin: 0 auto;
        white-space: pre-line;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 4rem;
    }

    .auto-aip__noquotes__info {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        align-content: center;
        justify-items: center;
        justify-content: center;
        gap: 0.5rem;
    }

    .auto-aip__noquotes__info h2,
    .auto-aip__noquotes__info h4,
    .auto-aip__noquotes__info p {
        margin-top: 0;
    }

    .auto-aip__noquotes__info__box {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 0.5rem;
        margin-top:1rem;
        padding-top:1rem;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
    }

    .auto-aip__noquotes__info__callback {
        width: 75%;
        text-align: center;
        background-color: white;
        margin: 0 auto;
        padding:1rem;
    }

    .auto-aip__noquotes__info__callback .button {
        margin-top: 1rem;
    }

    .auto-aip__noquotes__callback h2 {
        margin-top: 0;
    }

    .auto-aip__noquotes__info__content {
        padding:1rem;
    }

    @media (max-width: 650px) {
        .auto-aip__noquotes__info__callback {
            width: 100%;
        }
    }

</style>
