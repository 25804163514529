<script>
    import { createEventDispatcher } from 'svelte';
    const dispatch = createEventDispatcher();

    export let show = false;
    export let small = false;
    export let title = ""
    export let buttonCancelText = ""
    export let buttonCancelClass = "button fill red"
    export let buttonConfirmText = ""
    export let buttonConfirmClass = "button fill green"
    function confirmed() {
        show = false;
        dispatch('confirm');
    }
    function cancelled() {
        show = false;
        dispatch('cancel');
    }
</script>

<div class={`confirmation ${show ? 'show' : ''}`}>
    <div class="confimration__wrapper">
        {#if title.length}
            <div class="confirmation__title">{title}</div>
        {/if}
        <div class="confirmation__body">
            <slot></slot>
        </div>
        <div class="confirmation__actions">
            {#if buttonCancelText.length > 0}
                <button
                        class={`${buttonCancelClass} ${small ? 'small' : ''}`}
                        on:click="{cancelled}">{buttonCancelText}</button>
            {/if}
            {#if buttonConfirmText.length > 0}
            <button
                    class={`${buttonConfirmClass} ${small ? 'small' : ''}`}
                    on:click="{confirmed}">{buttonConfirmText}</button>
            {/if}
        </div>
    </div>
</div>

<style>

    .confirmation {
        display:none;
        position: fixed;
        z-index:999;
        left:0;
        top:0;
        width: 100%;
        height: 100%;
        overflow:auto;
        background-color: rgb(0,0,0); /* Fallback color */
        background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
        align-items: center;
        justify-content: center
    }

    .confimration__wrapper  {
        background-color: #fefefe;
        margin: 15% auto; /* 15% from the top and centered */
        padding: 20px;
        border: 1px solid #888;
        width: 100%; /* Could be more or less, depending on screen size */
        max-width: 600px;
        margin:1rem;
        border-radius: 0.75rem;
        text-align: left;
    }

    .confirmation__title {
        font-size: 1.5rem;
        font-weight: bold;
        padding-bottom: 0.5rem;
        border-bottom:1px solid #cccccc;
    }

    .confirmation__actions {
        display:flex;
        gap: 0.5rem;
        align-items: center;
        justify-content: end;
        padding-top:0.5rem;
    }

    .confirmation.show {
        display:flex;
    }

</style>