<script>
    import {arrayRange} from "../../Shared/utils";
    export let title = "";
    export let color = "";
    export let risk = 0;
    export let outOf = 0;
    export let groupedBy = 0;
    export let chance = "";
    export let premium = 0;
    export let mortgage = 0;

</script>

<div class={`insurance-panel ${color.toLowerCase() || ''}`}>
     <div class="insurance-panel__card">
        <div class="insurance-panel__risk">
            <p>You have a</p>
            <h3>{ risk } in { outOf }</h3>
            <div class="people">
            {#each arrayRange(0, outOf - 1, groupedBy) as index}
                <div class="people__group">
                    {#each arrayRange(index, (index + groupedBy) - 1, 1) as value}
                        {#if value < outOf}
                            {#if value < risk}
                                <svg width="16.4355" height="17.8809">
                                    <g>
                                        <rect height="17.8809" opacity="0" width="16.4355" x="0" y="0"/>
                                        <path d="M1.66992 17.8809L14.7656 17.8809C15.8105 17.8809 16.4355 17.3926 16.4355 16.582C16.4355 14.0625 13.2812 10.5859 8.21289 10.5859C3.1543 10.5859 0 14.0625 0 16.582C0 17.3926 0.625 17.8809 1.66992 17.8809ZM8.22266 8.83789C10.3125 8.83789 12.1289 6.96289 12.1289 4.52148C12.1289 2.10938 10.3125 0.322266 8.22266 0.322266C6.13281 0.322266 4.31641 2.14844 4.31641 4.54102C4.31641 6.96289 6.12305 8.83789 8.22266 8.83789Z" fill="#ffffff" fill-opacity="0.85"/>
                                    </g>
                                </svg>

                            {:else}
                                <svg width="17.0605" height="18.4082">
                                    <g>
                                        <rect height="18.4082" opacity="0" width="17.0605" x="0" y="0"/>
                                        <path d="M2.39258 18.4082L14.668 18.4082C16.2891 18.4082 17.0605 17.9199 17.0605 16.8457C17.0605 14.2871 13.8281 10.5859 8.53516 10.5859C3.23242 10.5859 0 14.2871 0 16.8457C0 17.9199 0.771484 18.4082 2.39258 18.4082ZM1.93359 16.9336C1.67969 16.9336 1.57227 16.8652 1.57227 16.6602C1.57227 15.0586 4.05273 12.0605 8.53516 12.0605C13.0078 12.0605 15.4883 15.0586 15.4883 16.6602C15.4883 16.8652 15.3906 16.9336 15.1367 16.9336ZM8.53516 9.375C10.8594 9.375 12.7539 7.30469 12.7539 4.78516C12.7539 2.28516 10.8691 0.322266 8.53516 0.322266C6.2207 0.322266 4.31641 2.32422 4.31641 4.80469C4.32617 7.31445 6.21094 9.375 8.53516 9.375ZM8.53516 7.90039C7.10938 7.90039 5.88867 6.5332 5.88867 4.80469C5.88867 3.10547 7.08984 1.79688 8.53516 1.79688C9.99023 1.79688 11.1816 3.08594 11.1816 4.78516C11.1816 6.51367 9.9707 7.90039 8.53516 7.90039Z" fill="#ffffff" fill-opacity="0.85"/>
                                    </g>
                                </svg>
                            {/if}
                        {/if}
                    {/each}
                </div>
            {/each}
            </div>
            <p>{ chance }</p>
        </div>
        <div class="insurance-panel__cover">
            <h4>{title}</h4>
            <p>Get cover from:</p>
            <h3>&pound;{premium.toFixed(0)}<span>/month</span></h3>
            <p class="black">total monthly cost inclusive of mortgage</p>
            <h3>&pound;{mortgage.toFixed(0)}</h3>
        </div>
    </div>
</div>

<style>

    .insurance-panel.orange {
        --color: #EC9246;
    }

    .insurance-panel.green {
        --color: #5BBDAF;
    }

    .insurance-panel.purple {
        --color: #894473;
    }

    .insurance-panel.blue {
        --color: #498BEB;
    }

    .insurance-panel {
        display:flex;
        flex-direction: column;
        align-content: space-between;
    }

    .insurance-panel .insurance-panel__card {
        display: flex;
        align-items: stretch;
        justify-content: space-evenly;
        border-radius: 1rem;
        outline: 4px solid var(--color);
        padding: 0;
        margin: 2px;
        flex-grow: 1;
    }

    .insurance-panel .insurance-panel__card h3 {
        font-size: 2.5em;
        font-weight: bold;
    }

    .insurance-panel .insurance-panel__card h3 span {
        font-size: 0.5em;
        font-weight: normal;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__risk,
    .insurance-panel .insurance-panel__card .insurance-panel__cover {
        flex-basis: 50%;
        margin:0;
        padding:1rem;
        font-weight: normal;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        gap: 1rem;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__risk {
        background: var(--color);
        border-radius: 1rem;
        color: white;
        margin: -2px;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__risk > *,
    .insurance-panel .insurance-panel__card .insurance-panel__cover > * {
        margin:0;
        padding:0;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__cover {
        color: var(--color);
    }

    .insurance-panel .insurance-panel__card .insurance-panel__cover h4 {
        font-size:1.5em;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__cover .black {
        color: black;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__risk .people {
        display: flex;
        flex-direction: column;
        gap:0.5rem;
    }

    .insurance-panel .insurance-panel__card .insurance-panel__risk .people .people__group {
        display: flex;
        align-items:center;
        justify-content: center;
        gap: 0.5rem;
    }

    @media (max-width: 500px) {
        .insurance-panel .insurance-panel__card {
            font-size: 0.9em;
        }
        .insurance-panel .insurance-panel__card h3 {
            font-size: 2em;
        }
        .insurance-panel .insurance-panel__card .insurance-panel__cover h4 {
            font-size:1.2em;
        }

    }


</style>