<NoSidebar title="Believe Web Portal Guide" titleClass="text-left" class="">

    <a href="/guide">Back to Guides</a>

    <div class="web-guide">

        <p>This user guide will take you through how to register and activate your unique customer portal. It will also detail how to:</p>

        <ul>
            <li>upload the requested documents</li>
            <li>supply any additional information</li>
            <li>
                <a href="/guide/acceptable-documents">what the lenders accept as documents / quality of images</a>
            </li>
        </ul>

        <hr/>

        <h3>Activation of your Portal Account</h3>

        <p>
            As part of the email which your Advisor will have sent you detailing the product we have found for you, there will be a link for you to register to use your dedicated and secure customer portal account.
        </p>
        <p>This is located towards the bottom of the email and will look like the below:</p>

        <img class="img-fluid" src="/guide/web/activation_email.png"/>

        <p>Once you click on the link in the email or type the one below into a web browser (i.e. Google Chrome), you will be able to register for your account.</p>
        <p><a href="https://portal.believemoney.com">https://portal.believemoney.com</a></p>

        <img class="img-fluid" src="/guide/web/welcome.png"/>

        <p>Once you have selected the &quot;Need an account? Register here!&quot; option, you will be taken to the register your e-mail address and to choose a password page.</p>

        <p><strong>Please note that the email you use during the registration process must be the same one you have provided to the advisor during your fact find and will be different for each applicant.</strong></p>

        <img class="img-fluid" src="/guide/web/register.png"/>

        <p>Once you have registered, you will then be sent an e-mail to verify your account and email address.</p>

        <img class="img-fluid" src="/guide/web/verify.png"/>

        <p>You will not be able to log in to your portal until the verification step has been completed.</p>

        <p>The link you receive expires after 1 hour for security reasons - if you click the link after this period, you will be advised to begin the registration process again.</p>

        <p>Once verified, you will see the below screen and can click the link to sign into your portal account as normal.</p>

        <img class="img-fluid" src="/guide/web/verified.png"/>

        <h3>Accessing an Activated Portal</h3>

        <p>If you have already activated your portal, you can use the same link as you used during registration, input your email and password and sign into your account.</p>

        <p><a href="https://portal.believemoney.com">https://portal.believemoney.com</a></p>

        <img class="img-fluid" src="/guide/web/welcome.png"/>

        <p>The main customer portal page will then load for you to use.</p>

        <img class="img-fluid" src="/guide/web/portal_home.png"/>

        <p>From this page you have several options in the blue buttons at the top.</p>

        <dl>
            <dt>My Loan</dt>
            <dd>Provides you with a breakdown of your agreement.</dd>
            <dt>To Do List</dt>
            <dd>Allows you to access your application to e-sign it and upload all requested documents.</dd>
            <dt>My Documents</dt>
            <dd>Allows you to upload any additional documents.</dd>
        </dl>

        <p>Or you can go directly to the To Do list by selecting the Red Banner.</p>

        <h3>Multiple Cases</h3>

        <p>In the event you have multiple cases with us (either loans or mortgages), you will find a case switcher option box at the top of the page once you have signed in, this will allow you to switch easily between your cases.</p>

        <img class="img-fluid" src="/guide/web/case_selector.png"/>

        <h3>Signing your Application Pack</h3>

        <p>To sign your Application pack you must be in the ‘To Do List’ tab and then you will need to select the ‘Proceed to Sign’ button. Once in the form you then have the ability to sign the document.</p>

        <img class="img-fluid" src="/guide/web/todolist_applicationpack.png"/>

        <p>Once you are in the sign document page, you can then select the signature applicant box which will open up a signature box.</p>

        <img class="img-fluid" src="/guide/web/sign_document.png"/>

        <p><strong>You will only be able to sign for yourself, any other applicants will also need to complete this step.</strong></p>

        <p>Once you have selected the ‘Signature applicant’ and have been presented with the signature box. Simply draw you signature inside the box with your finger or your mouse and either select ‘Clear’ to start again or select ‘Sign’ to accept the signature.</p>

        <img class="img-fluid" src="/guide/web/draw_signature.png"/>

        <h3>Uploading your documents</h3>

        <p>To upload the required documents, you need to click on the ‘To Do List’ tab.</p>

        <p>Once you have done this, you will then be presented with a list of the requirements that were added to your case and sent to you at the point of sale.</p>

        <img class="img-fluid" src="/guide/web/todolist.png"/>

        <p>You now have the option to click on ‘Add’ for each required document, this will allow you to either choose the document from a file of your choosing, or to take a photo of the document and upload it (screen shots are not recommended for uploading).</p>
        <p><strong>Each applicant will be able to see the requirements for each other and have the ability to upload the files on their behalf. Please note though you will not be able to sign any forms for them and this must be done by the correct applicant via their own portal.</strong></p>

        <p>When you have chosen the document and selected ‘Ok’ the ‘Start Uploading’ button will appear, when you select this, the document will then upload, and a tick will appear to the left of the documentation field to indicate the upload has occurred.</p>
        <p><strong>Please note – for a request of multiple documents – i.e. 3 x latest payslips - please add all three files before clicking Upload.</strong></p>

        <div class="img-aside">
            <img class="img-fluid" src="/guide/web/todolist_payslips.png"/>
            <img class="img-fluid" src="/guide/web/todolist_payslips_upload.png"/>
        </div>

        <p>Once these steps have been completed, we will receive the files and be able to check to ensure that the lender will accept them and continue to progress your case.</p>
        <p><a href="/guide/acceptable-documents">View our guide of acceptable documents and quality here</a>.</p>
        <p>You can also send us any other additional information by using the Documents tab and the Add document button.  You will them be asked what file you wish to upload and follow the same steps as above.</p>
        <p>Anything you have sent to us will also be visible in this tab for you.</p>

    </div>

</NoSidebar>

<script>
    import NoSidebar from '../../../Shared/Layouts/NoSidebar.svelte'
</script>

<style type="text/css">
    .web-guide {
        text-align: left;
    }
    .web-guide p,
    .web-guide ul li {
        line-height: 1.75rem;
    }
    .web-guide img {
        border: 1px solid #17174c;
        border-radius: 5px;
        margin: 0 auto 2rem auto;
        max-width: 600px;
        max-height: 500px;
    }
    .web-guide .img-aside {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
    .web-guide dt {
        font-weight: bold;
    }
    .web-guide dd {
        padding: 1rem 0 1rem 0;
    }
    @media screen and (max-width: 925px) {
        .web-guide img {
            max-width: 100%;
        }
    }
    @media screen and (max-width: 1200px) {
        .web-guide .img-aside {
            flex-direction: column;
        }
    }
</style>