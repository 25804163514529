<script>
    export let title = "";
    export let titleClass = "";
    export let transparent = false;
    export let nomargin = false;

    let classes = '';
    export { classes as class };

</script>

<div class={`sidebar ${classes || ''}`}>
    <h1 class="sidebar__title">{title}</h1>
    <div class="sidebar__wrapper">
        <div class="sidebar__main">
            <div class="sidebar__main__card" class:bg-white={!transparent} class:add-margin={!nomargin}>
                <div class={`sidebar__main__card__body ${titleClass}`}>
                    <h1 class={titleClass.length == 0 ? `text-center` : ''}>{title}</h1>
                    <slot></slot>
                </div>
            </div>
        </div>
    </div>
    <slot name="footer"></slot>
</div>

<style>

    :global(*[slot='sidebar']) {
        display:flex;
        z-index: 0;
    }

    .sidebar__title {
        display: none;
    }

    .sidebar {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        min-width: 100%;
        min-height: 100%;
        background: rgb(107,179,198);
        background:
                linear-gradient(164deg,
                rgba(107,179,198,1) 0%,
                rgba(250,250,250,1) 35%,
                rgba(250,250,250,1) 70%,
                rgba(107,179,198,1) 100%);

    }

    .sidebar__wrapper {
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        min-width: 100%;
        min-height: 100%;
        align-items: flex-start;
    }

    .sidebar__main {
        display: flex;
        flex-grow: 1;
        min-width: 100%;
        max-width: 100%;
    }

    .sidebar__main__card {
        flex-grow: 1;
        margin: 0;
        padding: 0;
        border-radius:0.5rem;
        min-height: 100%;
    }

    .add-margin {
        margin: 4rem 4rem 4rem 4rem;
        padding: 2rem;
    }

    .bg-white {
        background-color: white;
    }

    .sidebar__main__card__body {
        margin: 1rem 4rem;
        text-align:center;
    }

    .text-center {
        text-align: center;
    }

    @media(max-width: 850px) {

        :global(*[slot='sidebar']) {
            flex-direction: column;
        }

        .sidebar {
            background:
                    linear-gradient(164deg,
                    rgba(107,179,198,1) 0%,
                    rgba(250,250,250,1) 20%,
                    rgba(250,250,250,1) 70%,
                    rgba(107,179,198,1) 100%);
        }

        .sidebar__wrapper {
            flex-direction: column;
        }

        .add-margin {
            margin: 2rem;
            padding: 1rem;
        }

        .sidebar__main__card__body {
            margin: 1rem 0 0 0;
        }

    }

</style>
