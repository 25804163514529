<script>
  import {onMount} from "svelte";
  import {router} from "tinro";
  import {activeCaseData, user} from "../../Shared/store";

  let event;

  onMount(() => {
    const queryItems = location.search.slice(1).split("&").map(i => i.split("="));
    const query = {};
    for (const item of queryItems) {
      query[decodeURIComponent(item[0])] = decodeURIComponent(item[1]);
    }
    if (query.event !== "signing_complete" && query.event !== "continue_email") {
      router.goto("/documents");
    }
    event = query.event;
    if (event === "signing_complete") {
      const packId = sessionStorage.getItem("eSignPending");
      if (packId) {
        const alreadyCompleted = JSON.parse(sessionStorage.getItem("eSignComplete")) || [];
        alreadyCompleted.push(packId);
        sessionStorage.setItem("eSignComplete", JSON.stringify(alreadyCompleted));
        sessionStorage.removeItem("eSignPending");
        $activeCaseData.todo = $activeCaseData.todo.map(a => ({
          ...a,
          needs: a.needs.map(n => n.id === packId ? {...n, completed: true} : n)
        }));
      }
    }
  });

  function back() {
    router.goto("/todo");
  }
</script>
<div class="container">
    {#if event === "continue_email"}
        <h1>Please check your e-mail</h1>
        <p>
            We've sent you an e-mail containing a link to sign your document online.
        </p>
        <p>
            All you need to do is tap the link in the e-mail and you will be directed to the signing page.
        </p>
    {:else}
        <h1>Thank You</h1>
        {#if $user.signedIn}
            <p>
                Thanks for signing your documents online. They are now being processed and when complete will be
                available
                for
                you
                to download a signed copy on the <a href="/documents">documents page</a>.
            </p>
        {:else}
            <p>
                Thanks for signing your documents online. We will process them as soon as we can.
            </p>
        {/if}
    {/if}
    {#if $user.signedIn}
        <p>
            <button on:click={back}>&laquo; Back to To-Do List</button>
        </p>
    {/if}
</div>