<script>

  import {getContext, onMount} from 'svelte';
  import Upload from '../../Upload.svelte'
  import {router} from "tinro";
  import TypeForm from "../../TypeForm.svelte";
  import {quoteAccessCode} from "../../../utils";
  import { activePortal } from "../../../store";
  import api from "../../../api";

  // From Context
  const accessCode = getContext('accessCode');
  const portal = getContext('portal');

  // Local
  let incomeUpload = false;

  // Passed Vars
  export let quote;
  export let productQuote;
  export let currentStepIndex;
  export let selectedQuoteType;

  let subStep = 0;

  // Mount
  onMount(() => {
      calculateSubStep();
    router.subscribe(() => {
      window.scrollTo(0, 0);
    });
  })

  function calculateSubStep() {
    if (selectedQuoteType === 'productTransfer') {
      if (productTransferESign === null) {
        subStep = 0;
        currentStepIndex = 2;
        return;
      } else {
          if (productTransferESign.success === false || productTransferESign.signed === false) {
              subStep = 1;
              currentStepIndex = 3;
              return;
          }
          currentStepIndex = 4;
      }
    }
    subStep = (incomeUpload ? 3 : 2);
  }

  ////////////////////////////////
  // Callbacks
  function uploadBack() {
      incomeUpload = false;
      calculateSubStep();
  }

  async function uploadComplete() {
      incomeUpload = true;
      calculateSubStep();
  }

  async function incomeExpenditureComplete(e) {
      let responseId = e.detail;
      quote = await api.autoAipUpdate(portal, accessCode, "complete", {
          quoteIndex: productQuote.quoteIndex,
          productIndex: productQuote.index,
          responseId: responseId
      });
      productQuote = null;
  }

  ////////////////////////////////
  // Computed
  $: typeFormId = portal === "second-charge" ? "ynEmumBZ" : "j5u5tEMx";

  ////////////////////////////////
  // Product Transfer

  let productTransferESign = null;

  async function productTransferComplete() {
      //productTransferESign = await api.autoAipProductTransferESign(portal, accessCode);
      productTransferESign = {
          success: false,
          signed: false
      };
      window.scrollTo(0, 0);
      calculateSubStep();
  }

  async function productTransferFakeEsign() {
      productTransferESign = {
          success: true,
          signed: true
      };
      window.scrollTo(0, 0);
      calculateSubStep();
  }

</script>

<div class="auto-aip__proceed">

    {#if subStep === 0}
        <h2>Important Information</h2>
        <p>You've selected this mortgage by yourself and haven't received advice from us. This is know as 'execution-only'.</p>
        <p>By continuing, you agree to the following:</p>
        <ul>
            <li>{$activePortal.brand.name} will not give you any personal recommendations about this {$activePortal.brand.term}</li>
            <li>{$activePortal.brand.name} will not assess how suitable this {$activePortal.brand.term} is for you</li>
            <li>You waive any protections from the FCA's regulations on {$activePortal.brand.term} suitability, and your right to seek compensation.</li>
        </ul>
        <h4>Select 'Continue' to confirm your understand</h4>
        <div class="auto-aip__proceed__transfer">
            <button class="button orange fill pill" on:click={() => {
                selectedQuoteType = null;
                productQuote = null;
            }}>Back</button>
            <button class="button green fill pill" on:click={() => productTransferComplete()} >Continue</button>
        </div>
    {/if}

    {#if subStep === 1}
        <p>ESign Placeholder</p>
        <button class="button green fill pill" on:click={() => productTransferFakeEsign()} >Sign</button>
    {/if}

    {#if subStep === 2}
        <h2>Proof of Income</h2>
        <p>Please upload a proof of income document. This could be a recent payslip or bank statement, or SA302 document if you are self employed.</p>
        <Upload class="upload"
                autoaip
                uploadName='Income'
                uploadType='proof-of-income'
                on:uploaded={uploadComplete}
        />
    {/if}

    {#if subStep === 3}
        <TypeForm
                formId={typeFormId}
                accessCode={quoteAccessCode()}
                showBackButton={false}
                on:back={uploadBack}
                on:completed={incomeExpenditureComplete} />
    {/if}

</div>

<style>

    .auto-aip__proceed {
        text-align: center;
        margin: 1rem auto 0 auto;
        padding: 1rem;
        background-color: white;
        border: 1px solid rgba(0,0,0,0.1);
        border-radius: 0.75rem;
    }

    p {
        white-space: pre-line;
    }

    .auto-aip__proceed__box {
        display: flex;
        border: 2px solid #bfe5ee;
        border-radius: 0.75rem;
        padding: 2rem;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
    }

    .auto-aip__proceed h2 {
        padding:0;
        margin:0;
    }

    .auto-aip__proceed__transfer {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap:0.5rem;
    }

</style>
