<script>
    import {router} from "tinro";
    import {get} from "svelte/store";

    import api from "../../api";
    import {activeCaseData, refreshCase } from "../../store";
    import NeedsList from "./NeedsList.svelte";
    import LoadingIndicator from "../LoadingIndicator.svelte";

    let applicants;
    $: applicants = $activeCaseData ? $activeCaseData.todo.map(a => ({
        name: a.name,
        pendingNeeds: a.needs.filter(n => !n.completed),
        completedNeeds: a.needs.filter(n => n.completed)
    })) : [];

    let hasPendingNeeds;
    $: hasPendingNeeds = applicants.some(a => a.pendingNeeds.length > 0);

    let hasCompletedNeeds;
    $: hasCompletedNeeds = applicants.some(a => a.completedNeeds.length > 0);

    let multipleApplicants;
    $: multipleApplicants = applicants.length > 1;

    async function needUploaded(obj) {
        let need = obj.detail.need;
        let e = obj.detail.e;
        let docs = e.detail.completed.map(x => x.doc.id);
        if (need.id) {
            await api.needCompleted(need.id, docs);
            await refreshCase();
        }
    }

    let working = false;
    let esignUrl = null;

    // eversign events
    window.addEventListener("message", e => {
        if (e.data === "event_loaded") {
        } else if (e.data === "event_signed") {
            esignUrl = null;
            router.goto("/e-sign/complete?event=signing_complete");
        } else if (e.data === "event_declined") {
            esignUrl = null;
        } else if (e.data === "event_error") {
            esignUrl = null;
        }
        working = false;
    });

    async function eSign(obj) {
        let pack = obj.detail.need;
        if (working || !pack.link) return;
        sessionStorage.setItem("eSignPending", pack.id);
        working = true;
        esignUrl = pack.link;
    }

</script>

<div class="needs__wrapper">
    <div class="container">

    {#if esignUrl}
        <div class="esign">
            <iframe title="E-Signatures" src={esignUrl} frameborder="0"></iframe>
            <button class="close" on:click={() => esignUrl = null}>Close</button>
        </div>
    {:else }
        <div class="documents-needed">
            {#if hasPendingNeeds}
                <p>Please complete the following items, if you need to send us a document that isn't listed below, please <a
                        href="/documents">upload it here</a>.</p>
                {#each applicants as applicant}
                    {#if applicant.pendingNeeds.length}
                        {#if applicants.length > 1}
                            <h3>{applicant.name.forename} {applicant.name.surname}</h3>
                        {/if}
                        <NeedsList needs={applicant.pendingNeeds} name={applicant.name} on:uploaded={needUploaded}
                                   on:esign={eSign}/>
                    {/if}
                {/each}
            {:else}
                <p>There are no items in your to-do list at this time.</p>
                <p>If you need to send us a document <a href="/documents">upload it here</a>.</p>
            {/if}
            {#if hasCompletedNeeds}
                <hr/>
                <h2>Completed</h2>
                {#each applicants as applicant}
                    {#if applicant.completedNeeds.length}
                        {#if applicants.length > 1}
                            <h3>{applicant.name.forename} {applicant.name.surname}</h3>
                        {/if}
                        <NeedsList needs={applicant.completedNeeds}/>
                    {/if}
                {/each}
            {/if}
        </div>
    {/if}

    <LoadingIndicator description="Please wait..." bind:show={working}/>

    </div>
</div>

<style>

    .needs__wrapper {
        padding:1rem;
    }

    :global(.upload) {
        padding: 0;
    }

    div.esign {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 110;
    }

    div.esign > iframe {
        width: 100%;
        height: 100%;
    }

    div.esign .close {
        position: fixed;
        bottom: 2rem;
        right: 2rem;
        z-index: 200;
    }

</style>
