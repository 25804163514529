<script>
  import {onMount, createEventDispatcher} from "svelte"
  import { createWidget } from '@typeform/embed'
  import api from "../api"

  const dispatch = createEventDispatcher();

  // Props
  export let formId = "";
  export let accessCode = "";
  export let showBackButton = true;
  
  onMount(() => {
    createWidget(formId, {
      container: document.querySelector('#type-form'),
      hidden: {
        access_code: accessCode
      },
      onSubmit: async (event) => {
        dispatch('completed', event.response_id);
      }
    })
  })
</script>
<div id="type-form"></div>
{#if showBackButton}
    <button class="button back" on:click={() =>  dispatch('back')}>Back..</button>
{/if}
<style>
    @import '../../../../node_modules/@typeform/embed/build/css/widget.css';

    :global(.typeform-widget iframe) {
        width: 100%;
        border: none;
        height: 500px;
    }

    .button {
        margin: 1rem 0;
    }

</style>