<script>
    import {createEventDispatcher, tick} from 'svelte';
    const dispatch = createEventDispatcher();

    import Uploader from "../Components/Uploader.svelte";

    export let aip = false;
    export let autoaip = false;
    export let uploadName = "";
    export let uploadType = "default";

</script>

<div class={`${$$props.class ? $$props.class : ''}`}>
    {#if autoaip == false}
    <h2>Upload {uploadName ? `${uploadName} ` : ""}Document(s)</h2>
        {/if}
    <p>
        We can accept photos or PDF documents up to 25MB in size, and you can upload multiple files at a time.
    </p>
    <Uploader uploadName={uploadName}
              uploadType={uploadType}
              aip={aip}
              autoaip={autoaip}
              on:back={(e) => dispatch('back', e)}
              on:uploaded={(e) => dispatch('uploaded', e) } />

</div>

<style>
    h2 {
        margin-top:0;
    }
</style>
