import {employmentStatus, lenders} from "./lists";

export const additionalFieldsConfig = {
  "propertyValue": {
    title: "Property Value",
    description: "Please give us an estimate of how much your property is worth.",
    dataType: "number"
  },
  "chargeBalance": {
    title: "Outstanding Mortgage Balance",
    description: "Please give us an estimate of how much you have left to pay on your current mortgage.",
    dataType: "number"
  },
  "chargeTermRemaining": {
    title: "Mortgage Term Remaining",
    description: "Please give us an estimate of how long you have left on your current mortgage (in years)",
    dataType: "number"
  },
  "chargeLender": {
    title: "Current Mortgage Lender",
    description: "Please select your current mortgage lender.",
    dataType: "dropdown",
    options: lenders
  },
  "employmentStatus": {
    title: "Employment Status",
    description: "Please tell us your current employment status.",
    dataType: "dropdown",
    options: employmentStatus
  },
  "grossAnnualIncome": {
    title: "Gross Annual Income",
    description: "Please give us an estimate of your gross annual income.",
    dataType: "number"
  },
  "raiseAmount": {
    title: "Raise Amount",
    description: "Do you want to borrow more than your current mortgage balance?",
    dataType: "conditional",
    conditions: [
      {
        label: "Yes",
        value: true,
        field:
          {
            description: "Please tell us how much more you want to borrow.",
            dataType: "number"
          }
      },
      {
        label: "No",
        value: false
      }
    ],
    check: (value) => {
      return (Number(value ?? "0")) > 0;
    }
  }
}
