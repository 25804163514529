<script>

  import {createEventDispatcher, getContext} from 'svelte';
  import Quote from '../Components/Quote.svelte';
  import Callback from "../../Callback.svelte";
  import api from "../../../api";
  import {DateTime} from "luxon";

  const dispatch = createEventDispatcher();

  // From Context
  const accessCode = getContext('accessCode');
  const portal = getContext('portal');

  // Passed Vars
  export let quote = null;
  export let productQuote = null;

  // Computed Quotes
  $: selectedQuoteIndex = quote.selectedQuoteIndex && quote.selectedQuoteIndex.quote ? quote.selectedQuoteIndex.quote : 0;
  $: selectedProductIndex = quote.selectedQuoteIndex && quote.selectedQuoteIndex.product ? quote.selectedQuoteIndex.product : 0;

  $: selectedQuote = quote.quotes ? quote.quotes[selectedQuoteIndex] : null;
  $: selectedProduct = selectedQuote ? selectedQuote.products[selectedProductIndex] : null;

  // Local vars
  let callback = {
    date: '',
    time: ''
  };

  // Functions
  function formatCallbackTime() {
    if (!quote || !quote.callbackTime) return "";
    const d = DateTime.fromFormat(quote.callbackTime, "yyyy-MM-dd HH:mm", {
      zone: "Europe/London",
      locale: "en-GB"
    });
    const suffix = d.day === 1 || d.day === 21 || d.day === 31 ? "st" : d.day === 2 || d.day === 22 ? "nd" : d.day === 3 || d.day === 23 ? "rd" : "th";
    return `${d.weekdayLong} ${d.day}${suffix} ${d.monthLong} at ${d.hour}:${d.minute < 10 ? `0${d.minute}` : d.minute}`;
  }

  // Actions
  async function setCallback() {
    const x = callback.date.set(callback.time).toFormat("yyyy-MM-dd HH:mm")
    quote = await api.autoAipCallback(portal, accessCode, x);
    dispatch("callback");
  }
</script>

<div class="auto-aip__completed">
    <img src="/all-done.svg" alt="All Done" class="auto-aip__completed__image" />
    <div class="auto-aip__completed-text">
        <h2>All done!</h2>
        <h2>Things are moving now...</h2>
        <p>We'll get to work checking the affordability. We'll need to chat through a few bits about you and your
            finances.</p>
        {#if quote.callbackTime}
            <h3>Your callback time has been set for {formatCallbackTime(quote.callbackTime)}.</h3>
            <h3>Need to change it? Simply select a new time below.</h3>
        {:else}
            <h3>We'll call you as soon as possible.</h3>
            <h3>However if you prefer, you can arrange a date and time for us to call
                you below that suits you best.</h3>
        {/if}
        <Callback bind:callback={callback}/>
        <p>
            <button class="button green" disabled={!callback.date || !callback.time} on:click={() => setCallback()}>Set Callback</button>
        </p>
    </div>
    <Quote
            bind:product={selectedProduct}
            bind:quote={selectedQuote}
            bind:mainquote={quote}
            bind:productQuote={productQuote}
            primary="true"
            completed="true"
    />
</div>

<style>

    .auto-aip__completed {
        width: 100%;
        margin-top: 1rem;
        padding-top: 1rem;
        background-color: white;
        border: 3px solid #01b57a;
        border-radius: 0.75rem;
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        justify-content: space-between;
        align-content: stretch;
        text-align: center;
    }

    .auto-aip__completed p,
    .auto-aip__completed h1,
    .auto-aip__completed h2,
    .auto-aip__completed h3,
    .auto-aip__completed h4 {
        white-space: pre-line;
        margin: 0;
        padding: 0.5rem 0;
    }

    .auto-aip__completed-text {
        padding: 0 1rem;
    }

    .auto-aip__completed__image {
        width:20%;
        margin-left:auto;
        margin-right:auto;
    }

    @media (max-width: 768px) {
        .auto-aip__completed__image {
            width:40%;
        }
    }

    .auto-aip__completed .circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        margin: 0 auto 0.5rem auto;
        font-size: 1.25rem;
        border-radius: 50%;
        border: 2px solid #01b57a;
        font-family: "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
        font-weight: bold;
        aspect-ratio: 1;
        background: #01b57a;
        color: white;
    }

</style>
