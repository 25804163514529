<script>
    import NoSidebar from '../../Shared/Layouts/NoSidebar.svelte'
  import {router} from "tinro";
  import api from "../../Shared/api";
  import recaptcha from "../../Shared/recaptcha";

  let email = "", error = "", done = false, working = false;

  async function reset() {
    error = "";
    if (!email) {
      error = "Please enter your e-mail address";
      return;
    }
    if (!/^[^@]+@[^@]+\.[a-zA-Z]{2,}$/.test(email.trim())) {
      error = "Please enter a valid e-mail address";
      return;
    }
    working = true;
    recaptcha.action("reset_password", async (token) => {
      const result = await api.reset(email, token);
      working = false;
      if (!result.success) {
        error = result.error;
        return;
      }
      done = true;
    });
  }

  function home() {
    router.goto("/");
  }

  function handleEnter(e) {
    if (!working && e.key === "Enter") {
      reset();
    }
  }
</script>

<NoSidebar title="Reset your Account">

    <div class="reset">
        {#if !done}
            <p>
                If you're having trouble accessing your case, please enter your e-mail address below and we will reset it.
            </p>
            <div class="form">
                <label for="email">E-mail address:</label>
                <input id="email" bind:value={email} on:keyup={handleEnter}/>
            </div>
            {#if error}
                <span class="error">{error}</span>
            {/if}
            <button class="button" on:click={reset} disabled={working}>{ working ? "Please Wait..." : "Reset Account" }</button>
            <div class="links">
                <p>
                    <a href="/sign-in">Sign in here!</a>
                </p>
            </div>
        {:else}
            <h1>Thank You</h1>
            <p>
                If <strong>{email}</strong> matches an account on our system, we have sent you an e-mail with instructions
                on how to complete
                resetting your account.
            </p>
            <p>
                <button class="button" on:click={home}>Home</button>
            </p>
        {/if}
    </div>

</NoSidebar>

<style>

    .reset {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .form {
        margin-bottom:1rem;
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        align-items: center;
    }

    .error {
        margin-bottom:1rem;
        color:red;
    }

    .links {
        margin-top:1rem;
    }

</style>
