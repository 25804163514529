<script>
  import {activeCaseData} from "../../Shared/store";
  $: protection = $activeCaseData && $activeCaseData.protection ? $activeCaseData.protection : [];
  $: protectionStartingFrom = protection.length ? protection.sort((a, b) => a.premium - b.premium)[0].premium : null;
</script>

<div class="protection-alert">
    {#if protection.length }
        <div class="container">
            <a href="/protection" class="button green fill">
                <h3>Protection</h3>
                <p>If the worst was to happen, how would you look after your family and protect your home?</p>
                {#if protectionStartingFrom}
                    <p>Get cover, starting from as little as &pound;{protectionStartingFrom} per month.</p>
                {/if}
            </a>
        </div>
    {/if}
</div>

<style>

    .protection-alert {
        margin-bottom:0.5rem;
    }

    .protection-alert .button.green.fill {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 0.5rem;
        padding:1rem;
        text-align: left;
        border-radius: 1rem;
    }

    .protection-alert .button.green.fill > * {
        padding:0;
        margin:0;
        line-height: 1.7rem;
    }

</style>